import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styles/styles.css";
import { Button, DatePicker, Form } from "antd";

import { MdDelete } from "react-icons/md";
import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import {
  changeMarker,
  datesRangesActions,
  deleteListOfObservations,
  deleteObservationLocally,
  listProjects,
  observationTypesAction,
  validatObservationLocally,
  validateUnValidatedObservations,
} from "../../actions/projectActions";
import {
  useFetchProjectList,
  useFetchUnvalidatedObservaionList,
} from "../../queries/useMapQueriesHook";

import Loading from "../../components/LoadingError/Loading";

import Header from "../../components/Header/Header";
import dayjs from "dayjs";
import UnValidatedTable from "../../components/ListViewTable/UnValidatedTable";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import plus from "../../assets/images/plus__blue.svg";

import { useCallback } from "react";
import { useFetchSingleObs } from "../../queries/useFetchOptions";

import { useTranslation } from "react-i18next";
import CreateObservation from "../../components/modals/observations/createObs/CreateObservation";
import { userObservationsUpdateTracker } from "../../lib/userObservationsUpdateTracker";
import NewFemaleCreate from "../../components/modals/observations/newFemale/NewFemaleCreate";
import { useCreateObservationStore } from "../../lib/createObs/useCreateObservationData";

const { RangePicker } = DatePicker;

function UnvalidatedView() {
  const { t } = useTranslation();
  const successMessage = useSelector((state) => state.setSuccessMessage);
  const { createObsSuccessNotif, updateObsSuccessNotif } = successMessage;

  const singleObservationData = useSelector((state) => state.singleObsData);
  const { singleObsId } = singleObservationData;
  const { singleObs, refetchSIngleObs } = useFetchSingleObs({
    singleObsId,
  });

  useEffect(() => {
    document
      .querySelector(".openSearch")
      .addEventListener("click", function () {
        document.querySelector(".content-search").style.left =
          document.querySelector(".shorsearch").offsetLeft + "px";
        document.querySelector(".content-search").classList.remove("d-none");
      });
  }, []);

  // let inputValueArrays = [];

  // show action btn
  const [showActions, setShowActions] = useState(false);
  const [actionStyle, setActionStyle] = useState("btn-action-off");
  const [disabled, setDisabled] = useState(true);

  // Handle close create modal

  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleHideModalCreateModal = () => {
    setShowCreateModal(false);
  };

  // Fetch projectlist
  const userLogin = useSelector((state) => state.userLogin);
  // const listOfProjects = useSelector((state) => state.projectList);

  // fetching project list with react query
  const { isLoading } = useFetchProjectList();

  const [projectId, setProjectId] = useState();

  // local storage change
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  useEffect(() => {
    // setTime
    let projectId = localStorage.getItem("projectId");

    setProjectId(projectId);
  }, [localProjectId]);

  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listProjects());
  }, [dispatch, userInfo]);
  // const { projects } = listOfProjects;

  useEffect(() => {
    dispatch(
      observationTypesAction({
        observationTypesListFromReducer: [],
      })
    );
    dispatch(changeMarker({ id: "", index: "" }));
  }, [dispatch]);

  // date range picker functions
  const [openCalendar, setOpenCalendar] = useState(false);
  // Set dates

  const location = useLocation();
  const dashboardDates = location.state?.dashboardDates;

  const [dates, setDates] = useState(dashboardDates);
  const { setUserObsUpdateTracker } = userObservationsUpdateTracker();
  const [defaultStartDate, setDefaultStartDate] = useState(
    dashboardDates && dashboardDates.length > 0
      ? dayjs(dashboardDates[0], "YYYY-MM-DD")
      : null
  );

  const [defaultEndDate, setDefaultEndDate] = useState(
    dashboardDates && dashboardDates?.length > 0
      ? dayjs(dashboardDates[1], "YYYY-MM-DD")
      : null
  );
  const [form] = Form.useForm();

  const onButton = () => {
    setDefaultStartDate(null);
    setDefaultEndDate(null);
    form.resetFields();
    setDates([]);
    setUserObsUpdateTracker(true);
    dispatch(
      datesRangesActions({
        dateRangesArray: [0, 0],
      })
    );
    setOpenCalendar(false);
  };

  const onChangeRangeDate = (value, dateString) => {
    if (value) {
      let rd = value.map((items) => {
        let m = dayjs(items).toISOString();

        return m;
      });

      const startOfDateRange = new Date(rd[0]);
      startOfDateRange.setHours(1, 0, 0, 0);
      const endOfDateRange = new Date(rd[1]);
      endOfDateRange.setHours(23, 59, 59, 999);

      const startTimestamp = startOfDateRange.getTime() / 1000;
      const endTimestamp = endOfDateRange.getTime() / 1000;
      setUserObsUpdateTracker(true);
      dispatch(
        datesRangesActions({
          dateRangesArray: [
            Math.round(startTimestamp),
            Math.round(endTimestamp),
          ],
        })
      );
      setDates(rd);
      setOpenCalendar(false);
    }
  };

  // getting  id from lisviewtable
  const [idList, setIdList] = useState([]);

  const CallBack = useCallback(
    (childrenData) => {
      setIdList(childrenData);
      if (idList && idList.length !== 0) {
        setActionStyle("btn-action-on");
        setDisabled(false);
      } else {
        setActionStyle("btn-action-off");
        setDisabled(true);
        setShowActions(false);
      }

      return childrenData;
    },
    [idList]
  );

  const validObs = useSelector(
    (state) => state.validateUnvalidatedObservations
  );
  const { validateLoading } = validObs;

  const handleValidateObservations = () => {
    dispatch(validateUnValidatedObservations(idList));
    dispatch(validatObservationLocally(idList));
    setUserObsUpdateTracker(true);
  };

  // delete list of observations
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDeleteObservations = () => {
    dispatch(deleteListOfObservations(idList));
    dispatch(deleteObservationLocally(idList));
    setUserObsUpdateTracker(true);
    setShowConfirmModal(false);
  };
  const handleHideConfirmationModal = () => {
    setShowConfirmModal(false);
  };

  const createObs = useSelector((state) => state.createObservation);
  const { obsCreateLoading } = createObs;

  // Fetch and set data to send to table

  const updatedLocalProject = useSelector(
    (state) => state.updateProjectLocally
  );
  const { updtedObservationData } = updatedLocalProject;

  // Fetch unvalidated obserations with react query
  const { data, obsListLoading, isRefetching, queryRefetchObsList } =
    useFetchUnvalidatedObservaionList({ id: localProjectId });
  useEffect(() => {
    queryRefetchObsList();
  }, []);

  const [rowData, setRowsData] = useState([]);
  // setting observtion List to a use state
  const [allValideObs, setAllValideObs] = useState([]);

  useEffect(() => {
    const handleSetProjectObservation = () => {
      const obs =
        data &&
        data !== "undefined" &&
        data.pages.flatMap((page) => {
          return page.data;
        });
      setAllValideObs(obs);
      // setUpdateObsArray(obs);
    };
    handleSetProjectObservation();
  }, [data, isRefetching, updtedObservationData]);

  // edit obs list and add updated data
  useEffect(() => {
    if (updtedObservationData !== null) {
      // Iterate through the array using map
      if (updateObsSuccessNotif && updateObsSuccessNotif === true) {
        let uptObj = updtedObservationData;
        let updatedArray = allValideObs.map((obj) => {
          if (obj.id === uptObj.id) {
            return uptObj; // Replace with the separate object
          } else {
            return obj; // Keep the object as it is
          }
        });
        setAllValideObs(updatedArray);
      }
    }
  }, [updtedObservationData, updateObsSuccessNotif]);

  useEffect(() => {
   

    if (singleObsId && updateObsSuccessNotif) {
      refetchSIngleObs();
    }
    if (singleObsId && createObsSuccessNotif) {
      refetchSIngleObs();
    }
  }, [
    singleObsId,
    refetchSIngleObs,
    updateObsSuccessNotif,
    createObsSuccessNotif,
  ]);

  const localCreateObs = useSelector((state) => state.createObsStoreLocally);
  const { newObsCreated } = localCreateObs;
  const newCreatedObsId = useSelector((state) => state.newCreatedObservationId);
  const { newObsId } = newCreatedObsId;

  useEffect(() => {
    if (createObsSuccessNotif && createObsSuccessNotif === true) {
      if (newObsId) {
        if (newObsCreated) {
          newObsCreated.id = newObsId;

          setRowsData((prev) => [newObsCreated, ...prev]);
        }
      }
    }
  }, [newObsCreated, newObsId, createObsSuccessNotif]);

  useEffect(() => {
    if (
      singleObs &&
      singleObs !== "undefined" &&
      rowData &&
      rowData !== "undefined"
    ) {
      const newObsData = singleObs.data;

      setRowsData((prev) =>
        prev.map((item) => {
          if (item.id === newObsData.id) {
            return newObsData; // Replace the item with the previous object
          }
          return item; // Keep the item as it is
        })
      );
    }
  }, [singleObs]);

  useEffect(() => {
    setRowsData(allValideObs);
  }, [allValideObs]);

  return (
    <>
      <div
        className="container-fluid p-0"
        style={{
          overflowY: "hidden",
        }}
      >
        <div className="header-list-view">
          <Header />

          <div
            className="d-flex flex-column flex-shrink-0 menu-list position-absolute w-100 h-100 d-none"
            // style="z-index: 20;"
            style={{ zIndex: "20" }}
          >
            <div className="menu-close text-end p-2">
              <span className="close-menu">
                <span className="pe-sm-2">
                  <img
                    src="assets/elements/images/icon/close.svg"
                    alt=""
                    // style="height: 17px"
                    style={{ height: "17px " }}
                  />
                </span>
              </span>
            </div>

            <div className="title-men p-2 ps-3">MENU</div>

            <ul className="nav nav-pills flex-column mb-auto">
              <li className="nav-item">
                {/* <!-- <a href="#" className="nav-link btn btn-toggle nivo1" data-bs-toggle="collapse" data-bs-target="#beach-collapse" data-name="beach-collapse" aria-expanded="false">
            Beach
          </a> --> */}
                <a
                  className="nav-link btn btn-toggle-men nivo1 "
                  data-bs-toggle="collapse"
                  data-bs-target="#menu-obs-collapse"
                  data-name="beach-collapse"
                  aria-expanded="false"
                >
                  <span className="element-menu-top ps-4">
                    <img
                      src="assets/elements/images/icon/list2.svg"
                      alt=""
                      className="ico"
                    />
                  </span>
                  <span className="mx-2">Observationss</span>
                </a>

                <div className="collapse" id="menu-obs-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal small ">
                    <li className="btn-toggle-item">
                      <a
                        href="#"
                        className="nivo2 m-0 nav-link py-3 obs-list-item"
                      >
                        <span className="ms-2 me-sm-1">
                          <img
                            src="assets/elements/images/icon/obslist.svg"
                            alt=""
                          />
                        </span>{" "}
                        List View
                      </a>
                    </li>
                    <li className="btn-toggle-item">
                      <a
                        href="#"
                        className="nivo2 m-0 nav-link py-3 obs-list-item"
                      >
                        <span className="ms-2 me-sm-1">
                          <img
                            src="assets/elements/images/icon/mapview.svg"
                            alt=""
                          />
                        </span>{" "}
                        Map View
                      </a>
                    </li>
                    <li className="btn-toggle-item">
                      <a
                        href="#"
                        className="nivo2 m-0 nav-link py-3 obs-list-item"
                      >
                        <span className="ms-2 me-sm-1">
                          <img
                            src="assets/elements/images/icon/question.svg"
                            alt=""
                          />
                        </span>{" "}
                        Unvalidated (22)
                      </a>
                    </li>
                    <li className="btn-toggle-item">
                      <a
                        href="#"
                        className="nivo2 m-0 nav-link py-3 obs-list-item"
                      >
                        <span className="ms-2 me-sm-1">
                          <img
                            src="assets/elements/images/icon/newobs.svg"
                            alt=""
                          />
                        </span>{" "}
                        New Observation
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="checker empty"></div>
              </li>
              <hr style={{ margin: "0px" }} />
              <li className="nav-item ">
                <a className="nav-link btn btn-toggle-men nivo1 ">
                  <span className="element-menu-top  ps-4">
                    <img
                      src="assets/elements/images/icon/notif2.svg"
                      alt=""
                      className="ico"
                    />
                  </span>
                  <span className="mx-2">Notification</span>
                </a>
              </li>
              <hr style={{ margin: "0px" }} />
              <li className="nav-item ">
                <a className="nav-link btn btn-toggle-men nivo1 ">
                  <span className="element-menu-top  ps-4">
                    <img
                      src="assets/elements/images/icon/account.svg"
                      alt=""
                      className="ico"
                    />
                  </span>
                  <span className="mx-2">My account</span>
                </a>
              </li>
              <hr style={{ margin: "0px" }} />
              <li className="nav-item ">
                <a className="nav-link btn btn-toggle-men nivo1 ">
                  <span className="element-menu-top  ps-4">
                    <img
                      src="assets/elements/images/icon/logout.svg"
                      alt=""
                      className="ico"
                    />
                  </span>
                  <span className="mx-2">Log Out</span>
                </a>
              </li>
              <hr style={{ margin: "0px" }} />
            </ul>
          </div>

          <div className="row head02 px-2  ">
            <div className="mobile-action-btn">
              <div className="px-3 d-flex align-items-end relative justify-content-end mt-1 pt-1 act-cnt">
                <button
                  type="button"
                  className={`  px-2 ${actionStyle} `}
                  disabled={disabled}
                  // btn-action-on

                  onClick={() => {
                    setShowActions(!showActions);
                  }}
                >
                  Actions
                </button>
                {showActions && !disabled && (
                  <div
                    className="actionLists"
                    style={{
                      zIndex: "10",
                      width: "120px",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      // positio: "fixed",
                    }}
                  >
                    <span
                      className="d-block px-2 py-3 actionItem"
                      style={{
                        borderBottom: ".5px solid #f0f0f0",
                      }}
                      onClick={() => {
                        handleValidateObservations();
                      }}
                    >
                      <span className="ms-2 me-sm-1 ">
                        <AiOutlineQuestionCircle
                          style={{
                            fontSize: "20px",
                            marginBottom: "2px",
                          }}
                        />
                      </span>{" "}
                      Validate
                    </span>
                    <span
                      className="d-block px-2 py-3 actionItem"
                      onClick={() => {
                        setShowConfirmModal(true);
                      }}
                    >
                      <span className="ms-2 me-sm-1">
                        {/* <img src="assets/elements/images/icon/mapview.svg" alt="" /> */}
                        <MdDelete
                          style={{
                            fontSize: "20px",
                            marginBottom: "2px",
                          }}
                        />
                      </span>{" "}
                      Delete
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div
              className="menu-button-bas align-items-stretch pointer px-0 "
              style={{ width: "100%" }}
            >
              <div className=" d-flex align-items-center borderright menelem bpro pointer ">
                <div
                  className="d-none content-projet "
                  style={{ zIndex: "10" }}
                >
                  <div className="text-end">
                    <span className="ps-2 pe-2  d-inline-block projet-close pointer ">
                      <img
                        src="assets/elements/images/icon/close.svg"
                        alt=""
                        style={{ height: "15px" }}
                        // onClick={() => {
                        //   setToggleProjectList(false);
                        // }}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="px-4 d-flex align-items-center justify-content-center borderright flex-fill stafdate">
                <div className="calander-mobile">
                  <span className="pe-sm-2 ">
                    <img
                      src="assets/elements/images/icon/calander.svg"
                      alt=""
                      className="filtre"
                    />
                  </span>
                </div>

                <div className="px-4 d-flex align-items-center   flex-fill stafdate">
                  <div className="calander-mobile">
                    <span className="pe-sm-2 ">
                      <img
                        src="assets/elements/images/icon/calander.svg"
                        alt=""
                        className="filtre"
                      />
                    </span>
                  </div>
                  <span
                    className=" pe-sm-2 "
                    onClick={() => {
                      setOpenCalendar(!openCalendar);
                    }}
                  >
                    <img
                      src="assets/elements/images/icon/calander.svg"
                      alt=""
                      className="filtre"
                    />
                  </span>
                  <Form
                    form={form}
                    name="advanced_assessment_form"
                    className="ant-date-picker"
                  >
                    <Form.Item name="field">
                      <RangePicker
                        onClick={() => {
                          setOpenCalendar(true);
                        }}
                        defaultValue={[defaultStartDate, defaultEndDate]}
                        allowClear={true}
                        onChange={onChangeRangeDate}
                        placeholder={[
                          `${t("admin.exportData.startDate")}`,
                          `${t("admin.exportData.endDate")}`,
                        ]}
                        clearIcon={null}
                        open={openCalendar}
                      ></RangePicker>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className={`${
                          dates?.length === 0
                            ? "clear__filters__btn__disabled"
                            : "clear__filters__btn"
                        }`}
                        onClick={onButton}
                        style={{ marginLeft: "3rem", width: "92px" }}
                      >
                        {t("clear")}
                      </Button>
                    </Form.Item>
                  </Form>
                  <div
                    className="cont-date d-none px-4 "
                    style={{ zIndex: "10" }}
                  >
                    <div className="text-end">
                      <span className="p-2  d-inline-block date-close pointer ">
                        <img
                          src="assets/elements/images/icon/close.svg"
                          alt=""
                          style={{ height: "5px" }}
                        />
                      </span>
                    </div>
                    <div className="text-center">
                      <div
                        id="datestart"
                        className="border-date d-inline-block"
                      ></div>
                      <div
                        id="dateend"
                        className="border-date d-inline-block"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className="cont-date d-none px-4 "
                  style={{ zIndex: "10" }}
                >
                  <div className="text-end">
                    <span className="p-2  d-inline-block date-close pointer ">
                      <img
                        src="assets/elements/images/icon/close.svg"
                        alt=""
                        style={{ height: "15px" }}
                      />
                    </span>
                  </div>
                  <div className="text-center">
                    <div
                      id="datestart"
                      className="border-date d-inline-block"
                    ></div>
                    <div
                      id="dateend"
                      className="border-date d-inline-block"
                    ></div>
                  </div>
                </div>
              </div>

              <div className="px-3 d-flex align-items-center justify-content-center shorsearch borderright">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="openSearch"
                >
                  <button
                    type="button"
                    className="btn btn-light btn-sort ml-auto "
                  >
                    <img src="assets/elements/images/icon/search2.svg" alt="" />
                  </button>
                  <span
                    className="ms-sm-1 text-center search__text d-block"
                    style={{ fontSize: ".9rem" }}
                  >
                    {t("search")}
                  </span>
                </div>
              </div>

              <div className="px-4 d-flex align-items-center justify-content-center borderright ">
                <button
                  className="new__obs__btn"
                  style={{
                    cursor: `${obsCreateLoading ? "not-allowed" : "pointer"}`,
                  }}
                  disabled={obsCreateLoading}
                  onClick={() => {
                    setShowCreateModal(!showCreateModal);
                  }}
                >
                  <img src={plus} alt="add" width={"13px"} />
                  <span className="new__obs__text">{t("newObs1")}</span>
                </button>
              </div>

              <div className="px-3 d-flex align-items-center relative justify-content-center act-cnt">
                <button
                  type="button"
                  className={`  px-2 ${actionStyle} `}
                  disabled={disabled}
                  // btn-action-on

                  onClick={() => {
                    setShowActions(!showActions);
                  }}
                >
                  Actions
                </button>
                {showActions && !disabled && (
                  <div
                    className="actionLists"
                    style={{
                      zIndex: "10",
                      width: "120px",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      // positio: "fixed",
                    }}
                  >
                    <span
                      className="d-block px-2 py-3 actionItem"
                      style={{
                        borderBottom: ".5px solid #f0f0f0",
                      }}
                      onClick={() => {
                        handleValidateObservations();
                      }}
                    >
                      <span className="ms-2 me-sm-1 ">
                        <AiOutlineQuestionCircle
                          style={{
                            fontSize: "20px",
                            marginBottom: "2px",
                          }}
                        />
                      </span>{" "}
                      Validate
                    </span>
                    <span
                      className="d-block px-2 py-3 actionItem"
                      onClick={() => {
                        setShowConfirmModal(true);
                      }}
                    >
                      <span className="ms-2 me-sm-1">
                        {/* <img src="assets/elements/images/icon/mapview.svg" alt="" /> */}
                        <MdDelete
                          style={{
                            fontSize: "20px",
                            marginBottom: "2px",
                          }}
                        />
                      </span>{" "}
                      Delete
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <main style={{ zIndex: "2" }} className="main main-list-view">
          {validateLoading && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",

                transform: "translate(-50%, -50%)}",
              }}
            >
              <Loading />
            </div>
          )}

          {isLoading || obsListLoading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",

                transform: "translate(-50%, -50%)}",
              }}
            >
              <Loading />
            </div>
          ) : (
            <UnValidatedTable
              projectId={projectId}
              handleCallback={CallBack}
              dates={dates}
              obsData={
                // sirenInterNationalId !== Number(localProjectId) ? rowData : []
                rowData
              }
              obsListLoading={obsListLoading}
              isRefetching={isRefetching}
            />
          )}
        </main>

        {/* Create observation modal */}
        {showCreateModal && (
          <CreateObservation
            handleHideModalCreateModal={handleHideModalCreateModal}
          />
        )}
      </div>

      {showConfirmModal && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineInfoCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={"Delete Items?"}
          details={"Items will be deleted. This action cannot be undone."}
          btn1Text={"Cancel"}
          btn2Text={"Delete "}
          color={"#404040"}
          handleHideConfirmationModal={handleHideConfirmationModal}
          handleConfirmAction={handleDeleteObservations}
          closeConfirm={handleHideConfirmationModal}
        />
      )}
    </>
  );
}

export default UnvalidatedView;
