import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { MapContainer, TileLayer, useMap } from "react-leaflet";

import "./style.css";
import uploadImagePlaceHolder from "../../../../assets/images/uploadImagePlaceholder.svg";
import "../../style.css";
import { BiErrorCircle } from "react-icons/bi";
import ConfirmationModal from "../../ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  editeObservaion,
  refetchObs,
  singleObservation,
  updateObservationLocally,
} from "../../../../actions/projectActions";
import { observationStyle } from "../../../../utils/TypeObsStyle";
import { Switch } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ModalMarker from "../../../map/leafletMap/ModalMarker";
import {
  useFetchMiniCardObservaionList,
  useFetchObservaionList,
  useFetchUnvalidatedObservaionList,
} from "../../../../queries/useMapQueriesHook";
import { useTranslation } from "react-i18next";
import EditGroupFamSpecie from "./editModules/EditGroupFamSpecie";
import { useEditObservationStore } from "../../../../lib/editObservation/useEditObservationData";
import EditNewNest from "./editModules/dynamicForms/EditNewNest";
import { obsertionTypes } from "../../../../constants/observationsTypes";
import EditFemaleEncounter from "./editModules/dynamicForms/EditFemaleEncounter";
import EditObsSiteSegment from "./editModules/EditObsSiteSegment";
import EditImageIpload from "./editModules/EditImageIpload";
import EditObsType from "./editModules/EditObsType";
import EditCordsInput from "./editModules/EditCordsInput";
import EditDateTime from "./editModules/EditDateTime";
import ObservationTypePill from "../../ObservationTypePill";
import { userObservationsUpdateTracker } from "../../../../lib/userObservationsUpdateTracker";

dayjs.extend(customParseFormat);

function SetMap({ location, lat, long, typeObservation }) {
  const map = useMap();
  if (true) {
    map.flyTo(location, 8);
  }

  return location ? (
    <ModalMarker
      observation={null}
      lat={lat}
      long={long}
      typeObservation={typeObservation}
    />
  ) : null;
}
const EditObservation = ({
  editData,

  closeIsEditModal,
}) => {
  const { setUserObsUpdateTracker } = userObservationsUpdateTracker();
  const { t } = useTranslation();
  const {
    site,
    segment,
    displaySite,
    displaySegment,
    observation,
    obsId,
    classData,
    family,
    species,
    displayObserVationType,
    displayGroupe,
    displaySpecies,
    displayFamily,
    newLocalLong,
    newLocalLat,
    currentTime,
    seconds,
    newLat,
    newLong,
    convertedDate,
    note,
    deadOrAlive,
    prevImages,
    changeTracker,
    crawlWidth,
    // new nest
    newNestId,
    newNestCollector,
    newNestHighTideDist,
    newNestStatus,
    newNestEstHatchDate,
    newNestEstExhumDate,

    // Femal encounter
    newFemEncTagLeft,
    newFemEncTagRight,
    newFemEncLength,
    newFemEncWidth,

    setNote,
    setDisplayObservationType,
    setCrawlWidth,
    setPrevImages,
    setNewLocalLat,
    setNewLocalLong,
    setObsId,
    setDeadOrAlive,
    setChangeTracker,
    resetEditDataStore,
  } = useEditObservationStore();
  // siteId
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const { queryRefetchObsList } = useFetchUnvalidatedObservaionList({
    id: localProjectId,
  });
  const { queryRefetchValidObsList } = useFetchObservaionList({
    id: localProjectId,
  });
  const { miniCardQueryRefetchObsList } = useFetchMiniCardObservaionList({
    id: localProjectId,
  });
  const successMessage = useSelector((state) => state.setSuccessMessage);
  const { updateObsSuccessNotif } = successMessage;
  const [editModalState, setEditModalState] = useState(true);
  const [groupChangeTracker, setGroupChangeTracker] = useState(false);
  //****** */ End of data fetching and setting***********

  useEffect(() => {
    if (editData) {
      setObsId(editData.id);
      setNote(editData.note);
      setDeadOrAlive(editData.dead);

      const values = Object.values(editData.images);
      const filteredImages = values.filter(
        (value) => value !== "NULL" && value !== ""
      );
      setPrevImages(filteredImages);
    }
  }, [editData]);

  // delete single image

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [imageWasDeleted, setImgWasDeleted] = useState(false);
  const [trackDeleteImage, setTrackDeleteImage] = useState(false);
  // set coordinates

  useEffect(() => {
    if (editData) {
      setNewLocalLat(editData.coordX);
      setNewLocalLong(editData.coordY);
    }
  }, [editData]);

  const cordRef = useRef();

  const dispatch = useDispatch();
  // const [setsetLocalTimeForUpdat, setsetsetLocalTimeForUpdate] = useState();

  // dead or alive status change

  const [checkedAliveValue, setcheckedAliveValue] = useState();
  const [status, setStatus] = useState("");
  const statusChange = (checked) => {
    setChangeTracker(true);
    if (checked) {
      setDeadOrAlive(0);
      // setcheckedAliveValue(true);
    } else {
      setDeadOrAlive(1);
      // setcheckedAliveValue(false);
    }
  };

  useEffect(() => {
    if (editData) {
      if (deadOrAlive === 0) {
        setcheckedAliveValue(true);
        setStatus(`${t("alive")}`);
      } else {
        setcheckedAliveValue(false);
        setStatus(`${t("dead")}`);
      }
    }
  }, [deadOrAlive, editData, t]);

  const handleSaveEdits = async () => {
    const data = {
      obsId,
      newLocalLong,
      newLocalLat,
      site,
      segment,
      observation,
      classData,
      family,
      species,
      prevImages,
      convertedDate,
      time: currentTime + `:${seconds}`,
      dead: deadOrAlive,
    };
    const timeStr = currentTime + `:${seconds}`;
    const dateStr = convertedDate;

    // Splitting time into hours, minutes, and seconds
    const [hours, minutes, secondss] = timeStr.split(":");

    // Splitting date into year, month, and day
    const [year, month, day] = dateStr.split("-");

    // Setting the desired timezone
    // Adjusting the UTC offset
    const dt = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, secondss)
    );

    // Creating the final object
    const localdateObject = {
      date: dt.toISOString().slice(0, 23),
      timezone_type: 3,
      timezone: "UTC",
    };

    const updtedObservationData = {
      id: editData.id,
      projectId: editData.projectId,
      projectName: editData.projectName,
      idInaturalist: editData.idInaturalist,
      coordX: newLocalLat,
      coordY: newLocalLong,
      note: editData.note,
      alpha: editData.alpha,
      collector: editData.collector,
      images: {
        img1: prevImages[0] || "",
        img2: prevImages[1] || "",
        img3: prevImages[2] || "",
        img4: prevImages[3] || "",
      },
      dead: deadOrAlive,
      status: editData.status,
      TypeObservationId: observation,
      TypeObservation: displayObserVationType,
      specieId: species,
      specie: displaySpecies,
      familyId: family,
      family: displayFamily,
      groupId: classData,
      group: displayGroupe,
      user: editData.user,
      date: localdateObject,
      updatedate: null,
      segment: displaySegment,
      site: displaySite,
    };

    dispatch(editeObservaion(data));
    dispatch(updateObservationLocally(updtedObservationData));
    resetEditDataStore();
    closeIsEditModal();
  };
  useEffect(() => {
    if (updateObsSuccessNotif && updateObsSuccessNotif === true) {
      queryRefetchObsList();
      queryRefetchValidObsList();
      miniCardQueryRefetchObsList();
    }
  }, [updateObsSuccessNotif]);

  // confirm exit edit
  const refetchObsList = useSelector((state) => state.setRefetchObsList);
  const { refetchObsValue } = refetchObsList;
  const [confirmExit, setConfirmExit] = useState(false);
  const handleExitEdit = () => {
    if (trackDeleteImage) {
      dispatch(refetchObs({ refetchObsValue: !refetchObsValue }));
    }
    resetEditDataStore();
    closeIsEditModal();
    setConfirmExit(true);
  };
  const handleCloseConfirm = () => {
    setConfirmExit(false);
  };

  useEffect(() => {
    dispatch(singleObservation({ singleObsId: editData.id }));
  }, [editData, dispatch]);

  // input tracker

  const handleShowConfirmModal = () => {
    if (changeTracker) {
      setConfirmExit(true);
    } else {
      //
      if (imageWasDeleted) {
        resetEditDataStore();
        handleSaveEdits();
        closeIsEditModal();
      } else {
        handleExitEdit();
      }
    }
  };

  return (
    <div className="mvp__modals">
      <Modal
        show={true}
        // showModal

        backdrop="static"
        centered
        className="mx-auto mvp__modals edit__observation "
        keyboard={false}
        size="lg"
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="bgPrimary modal__header "
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title">
            {t("editObs")}
          </Modal.Title>
          <div className="">
            <span
              className="pointer"
              onClick={() => {
                handleShowConfirmModal();
              }}
            >
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>

        {editModalState ? (
          <>
            <div
              className="modal-top"
              style={{ borderBottom: "0.3px solid #ebf0ec" }}
            >
              <div
                className="modal-map"
                style={{ width: "50%", height: "100%", background: "grey" }}
              >
                {newLocalLat && newLocalLong && (
                  <MapContainer
                    center={[
                      newLocalLat && newLocalLat,
                      newLocalLong && newLocalLong,
                    ]}
                    zoom={12}
                    scrollWheelZoom={true}
                    zoomControl={false}
                    layer-type="base"
                    style={{ width: "100%", height: "100%", zIndex: "1" }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {newLat && newLong && (
                      <SetMap
                        location={[newLat, newLong]}
                        project={editData}
                        lat={newLat}
                        long={newLong}
                        typeObservation={displayObserVationType}
                      />
                    )}
                    <ModalMarker observation={editData} />
                  </MapContainer>
                )}
              </div>
              {/* 5.02568094545649, 12.898477862167748 */}
              <div
                className="modal-imgs "
                style={{ width: "50%", height: "100%", position: "relative" }}
              >
                {prevImages && prevImages.length !== 0 ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        backgroundImage: `url(${prevImages[0]})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: " center center",
                        backgroundSize: "cover",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <div
                        className="text-center"
                        style={{
                          color: "#096DBB",

                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditModalState(!editModalState);
                        }}
                      >
                        <img
                          src={uploadImagePlaceHolder}
                          style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              {prevImages && prevImages.length !== 0 && (
                <div
                  className="modal-photo-btn"
                  onClick={() => {
                    setEditModalState(!editModalState);
                  }}
                >
                  {prevImages.length > 1 ? (
                    <>
                      <span className="mx-1">+</span> {prevImages?.length - 1}
                    </>
                  ) : (
                    ""
                  )}

                  {prevImages.length > 1 ? (
                    <span className="mx-1">more</span>
                  ) : (
                    <>
                      {prevImages.length} <span className="mx-1">photo</span>
                    </>
                  )}
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.276974 0.159762C0.0691454 0.372745 0.0691099 0.718125 0.27701 0.931144L5.22321 5.99987L0.276974 11.0689C0.0691454 11.2818 0.0691099 11.6272 0.27701 11.8402C0.484874 12.0533 0.821864 12.0533 1.02973 11.8402L6.35231 6.38555C6.45212 6.28325 6.50819 6.14453 6.50819 5.99987C6.50819 5.85522 6.45209 5.71645 6.35227 5.6142L1.02969 0.1598C0.821864 -0.0532551 0.484839 -0.053256 0.276974 0.159762Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
            <div>
              <div
                className="modalBody container-fluid "
                // style={{ width: "100%" }}
              >
                <div className="row ">
                  <div className="col-12 px-4 pt-2 ">
                    <span className="f-bold me-4" style={{ fontSize: ".9rem" }}>
                      Obs {editData && editData.id}
                    </span>
                    <ObservationTypePill
                      displayObserVationType={displayObserVationType}
                    />
                  </div>
                  <div className="col-5 text-end"></div>
                </div>
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {" "}
                    {t("projectName")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {editData && editData.projectName}
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {t("author")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {editData && editData.user}
                  </div>
                </div>

                <EditDateTime editData={editData} />
                <EditCordsInput cordRef={cordRef} />

                <EditObsSiteSegment editData={editData} />

                <EditObsType
                  editData={editData}
                  setGroupChangeTracker={setGroupChangeTracker}
                />

                <EditGroupFamSpecie
                  editData={editData}
                  groupChangeTracker={groupChangeTracker}
                  setGroupChangeTracker={setGroupChangeTracker}
                />
                <div
                  style={{
                    // borderBottom: ".4px solid #cccc ",
                    padding: "0 10px",
                  }}
                  className="row   py-1"
                >
                  <div
                    className="dead-alive   d-flex  px-3 py-2"
                    style={{
                      justifyContent: "space-between",
                      fontSize: "14px",
                      color: "#595959",
                      marginBottom: "5px",
                      background: "#fff",
                    }}
                  >
                    <div> {t("status")}</div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          marginRight: "15px",
                        }}
                      >
                        {status}
                      </div>
                      <Switch
                        checked={checkedAliveValue}
                        onChange={statusChange}
                      />
                    </div>
                  </div>
                </div>
                {/* DYNAMIC FORM */}
                <div>
                  <div>
                    {(displayObserVationType === obsertionTypes.nest.VE ||
                      displayObserVationType === obsertionTypes.nest.VF) && (
                      <EditNewNest editData={editData} />
                    )}

                    {(displayObserVationType === obsertionTypes.female.VE ||
                      displayObserVationType === obsertionTypes.female.VF) && (
                      <EditFemaleEncounter editData={editData} />
                    )}

                    {/* Crawl Width */}
                    <div
                      className="row px-3 modal__obs__details__row mt-1"
                      style={{
                        borderTop:
                          displayObserVationType !== "Female encounter" &&
                          displayObserVationType !== "Nest"
                            ? ".5px solid #CCCCCC"
                            : "",
                      }}
                    >
                      <div className="col-5 modal__detail__title">
                        Crawl Width
                      </div>
                      <div className="col-7 modal__title__value">
                        <div className="input-container">
                          <input
                            inputmode="numeric"
                            type="text"
                            id="crawl-width"
                            name="crawlWidth"
                            className={`crud_select crud-input`}
                            style={{ width: "101%" }}
                            // value={editData.crawlWidth} Needs to be added to API
                            onChange={(e) => {
                              setCrawlWidth(e.target.value);
                              setChangeTracker(true);
                            }}
                          />
                          <span className="unit">(cm)</span>
                        </div>
                      </div>
                    </div>
                    {/* Notes */}
                    <div>
                      <div className="row px-3 modal__obs__details__row mt-1">
                        <div className="col-5 modal__detail__title">Notes</div>
                        <div className="col-7 modal__title__value">
                          <input
                            type="text"
                            id="notes"
                            name="notes"
                            className={`crud_select crud-input`}
                            style={{ width: "101%" }}
                            value={note}
                            onChange={(e) => {
                              setNote(e.target.value);
                              setChangeTracker(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal-footers mt-2"
              style={{
                borderRadius: "8px",
              }}
            >
              <div
                className="d-flex justify-content-end"
                style={{
                  width: "100%",
                }}
              >
                <div
                  className="modal_obs__btn edit"
                  onClick={() => {
                    handleShowConfirmModal();
                  }}
                >
                  {t("cancelBtn")}
                </div>
                <div
                  className="modal_obs__btn edit validate"
                  onClick={() => {
                    // setShowModal(false);
                    handleSaveEdits();
                  }}
                >
                  {t("saveChanges")}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Modal.Body>
              <EditImageIpload
                editData={editData}
                editModalState={editModalState}
                setEditModalState={setEditModalState}
                userInfo={userInfo}
                setTrackDeleteImage={setTrackDeleteImage}
                setImgWasDeleted={setImgWasDeleted}
              />
            </Modal.Body>
          </>
        )}
      </Modal>

      {/* Confirm edit exist */}
      {confirmExit && (
        <ConfirmationModal
          cofirmSourceIcon={
            <BiErrorCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={`${t("saveChanges")} ?`}
          details={t("lostUnsaved")}
          btn1Text={t("dontSave")}
          btn2Text={t("saveBtn")}
          color={"#404040"}
          handleHideConfirmationModal={handleExitEdit}
          handleConfirmAction={handleSaveEdits}
          closeConfirm={handleCloseConfirm}
        />
      )}
    </div>
  );
};
export default EditObservation;
