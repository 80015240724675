import React, { useEffect, useState } from "react";
import "./menuadmin.css";
import {
  changeAccount$,
  createProject$,
  getUser,
  operationOnProject,
  projectCreationModification$,
  saveAction,
  saveProjectAction,
  setChangeAccount,
  setCreateProject,
  user$,
} from "../../services/rxjs";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userActions";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const Menuadmin = ({ childrenCheck, fname, lname, job, setmenu }) => {
  // translation
  const { t } = useTranslation();

  const [select, setSelect] = useState(setmenu);
  const [checkProject, setCheckProject] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modAccount, setModAccount] = useState(false);
  const [showAccoutModal, setShowAccoutModal] = useState(false);
  const [menuToOpen, setMenuToOpen] = useState(1);
  const [creationModification, setCreationModification] = useState(false);
  const [titleMsg, setTitleMsg] = useState(`${t("admin.modal.leavePage")}`);
  const [contentMsg, setContentMsg] = useState(
    `${t("admin.modal.projectNotCreated")}`
  );
  const [cancelMsg, setCancelMsg] = useState(`${t("admin.modal.leave")}`);
  const [acceptMsg, setAcceptMsg] = useState(`${t("admin.modal.stay")}`);
  const [user, setUser] = useState({
    data: { firstname: fname, lastname: lname, fonction: job },
  });

  useEffect(() => {
    user$.subscribe((user) => setUser(user));
    getUser();
    createProject$.subscribe((check) => setCheckProject(check));
    changeAccount$.subscribe((mod) => setModAccount(mod));
    projectCreationModification$.subscribe((val) =>
      setCreationModification(val)
    );

    setUser((prevUser) => ({
      ...prevUser,
      data: {
        ...prevUser.data,
        fonction: job,
      },
    }));
  }, [job]);

  const dispatch = useDispatch();
  const history = useHistory();
  const logoutHandler = () => {
    history.push("/");
    dispatch(logout());
  };

  const menuChange = (menu) => {
    setMenuToOpen(menu);

    setContentsMsg();
    if (checkProject || modAccount) {
      if (!modAccount) setShowModal(true);
      else setShowAccoutModal(true);
    } else {
      goto(menu);
    }
  };

  const setContentsMsg = () => {
    if (creationModification === 0) {
      setTitleMsg(`${t("admin.modal.leavePage")}`);
      setContentMsg(`${t("admin.modal.projectNotCreated")}`);
      setCancelMsg(`${t("admin.modal.leave")}`);
      setAcceptMsg(`${t("admin.modal.stay")}`);
    } else {
      setTitleMsg(`${t("saveChanges")}`);
      setContentMsg(`${t("lostUnsaved")}`);
      setCancelMsg(`${t("dontSave")}`);
      setAcceptMsg(`${t("saveBtn")}`);
    }
  };

  const goto = (menu) => {
    setCreateProject(false);
    setChangeAccount(false);
    setSelect(menu);

    childrenCheck(menu);
  };

  const saveAccount = () => {
    saveAction().then(() => {
      setShowAccoutModal(false);
      goto(menuToOpen);
    });
  };

  return (
    <div className="admin-menu">
      <div className="infouser">
        {user.data.firstname && user.data.lastname ? (
          <div className="flname">
            {user.data.firstname} {user.data.lastname}
          </div>
        ) : (
          <div className="flname">{t("admin.profile.myAccount")}</div>
        )}
        <div className="job">{user.data?.fonction}</div>
      </div>

      <div
        className={
          "admin-menu-item curpoint " + (select === 1 ? "actived" : "")
        }
        onClick={() => {
          menuChange(1);
        }}
      >
        {t("admin.sideBar.account")}
      </div>
      <div
        className={
          "admin-menu-item curpoint " + (select === 2 ? "actived" : "")
        }
        onClick={() => {
          menuChange(2);
        }}
      >
        {t("admin.sideBar.project")}
      </div>
      <div
        className={
          "admin-menu-item curpoint " + (select === 4 ? "actived" : "")
        }
        onClick={() => {
          menuChange(4);
        }}
      >
        Sites/Segments
      </div>
      <div
        className={
          "admin-menu-item curpoint " + (select === 5 ? "actived" : "")
        }
        onClick={() => {
          menuChange(5);
        }}
      >
        Questions
      </div>
      {/* , */}
      <div
        className={
          "admin-menu-item curpoint " + (select === 3 ? "actived" : "")
        }
        onClick={() => {
          menuChange(3);
        }}
      >
        {t("admin.sideBar.members")}
      </div>
      <div className="admin-menu-btn-item">
        <button
          type="button"
          className="btn btn-light btn-sear btn-logout"
          onClick={() => {
            logoutHandler();
            localStorage.removeItem("userPrRole");
          }}
        >
          {t("admin.sideBar.logout")}
        </button>
      </div>

      <Modal
        show={showModal}
        // onHide={hideModal}
        backdrop="static"
        centered
        className="mx-auto"
        keyboard={false}
        // size="sm"
        dialogClassName="tailledeletemodal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div className=" text-end p-2 ">
          <span
            className="pe-sm-2 pointer "
            onClick={() => {
              setShowModal(false);
            }}
          >
            <img
              src="assets/elements/images/cross.png"
              alt=""
              style={{ height: "15px", cursor: "pointer" }}
            />
          </span>
        </div>

        <Modal.Body className="pe-4 ps-4">
          <div className=" d-flex justify-content-center">
            <img
              src="assets/elements/images/delete_icon2.png"
              alt=""
              width={40}
            />
          </div>
          <div className="text-center h5 mt-3">{titleMsg} ?</div>
          <div className="text-center mb-3">{contentMsg}</div>
        </Modal.Body>

        <div
          className="d-flex mt-3"
          style={{
            borderTop: ".5px solid #B3B3B3",
          }}
        >
          <div
            className="text-center p-2 fs-7 cancelbtn"
            style={{
              borderRight: ".5px solid #B3B3B3",
              width: "50%",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowModal(false);
              goto(menuToOpen);
            }}
          >
            {cancelMsg}
          </div>
          <div
            className="p-2 text-center fs-7 validbtn"
            style={{
              width: "50%",
              color: "#404040",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowModal(false);
              if (creationModification) {
                saveProjectAction();
                goto(menuToOpen);
              }
            }}
          >
            {acceptMsg}
          </div>
        </div>
      </Modal>

      <Modal
        show={showAccoutModal}
        // onHide={hideModal}
        backdrop="static"
        centered
        className="mx-auto"
        keyboard={false}
        // size="sm"
        dialogClassName="tailledeletemodal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div className=" text-end p-2 ">
          <span
            className="pe-sm-2 pointer "
            onClick={() => {
              setShowAccoutModal(false);
            }}
          >
            <img
              src="assets/elements/images/cross.png"
              alt=""
              style={{ height: "15px", cursor: "pointer" }}
            />
          </span>
        </div>

        <Modal.Body className="pe-4 ps-4">
          <div className=" d-flex justify-content-center">
            <img
              src="assets/elements/images/delete_icon2.png"
              alt=""
              width={40}
            />
          </div>
          <div className="text-center h5 mt-3"> {t("saveChanges")} ?</div>
          <div className="text-center mb-3"> {t("lostUnsaved")}</div>
        </Modal.Body>

        <div
          className="d-flex mt-3"
          style={{
            borderTop: ".5px solid #B3B3B3",
          }}
        >
          <div
            className="text-center p-2 fs-7 cancelbtn"
            style={{
              borderRight: ".5px solid #B3B3B3",
              width: "50%",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowAccoutModal(false);
              goto(menuToOpen);
            }}
          >
            {t("dontSave")}
          </div>
          <div
            className="p-2 text-center fs-7 validbtn"
            style={{
              width: "50%",
              color: "#404040",
              cursor: "pointer",
            }}
            onClick={() => {
              saveAccount();
            }}
          >
            {t("saveBtn")}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Menuadmin;
