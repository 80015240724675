import React, { useCallback, useEffect, useState } from "react";

import "./styles/styles.css";
import { Button, DatePicker, Form, Popconfirm } from "antd";

import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import {
  changeMarker,
  datesRangesActions,
  deleteListOfObservations,
  deleteObservationLocally,
  listProjects,
  observationTypesAction,
  successNofit,
  uploadListOfObservationsToInaturalist,
} from "../../actions/projectActions";
import {
  useFetchObservaionList,
  useFetchProjectList,
} from "../../queries/useMapQueriesHook";
import plus from "../../assets/images/plus__blue.svg";
import Loading from "../../components/LoadingError/Loading";

import Header from "../../components/Header/Header";
import dayjs from "dayjs";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { MdDelete } from "react-icons/md";
import ListViewTable from "../../components/ListViewTable/ListViewTable";

import { useFetchSingleObs } from "../../queries/useFetchOptions";
import { useTranslation } from "react-i18next";
import { usePatrolVariables } from "../../lib/usePatrolVariable";
import CreateObservation from "../../components/modals/observations/createObs/CreateObservation";
import { userObservationsUpdateTracker } from "../../lib/userObservationsUpdateTracker";

const { RangePicker } = DatePicker;

function ListView() {
  const { t } = useTranslation();

  const successMessage = useSelector((state) => state.setSuccessMessage);

  const { updateObsSuccessNotif, deleteObsSuccessNotif, uploadSuccessNotif } =
    successMessage;
  // is user admin
  const userRoleOnProject = useSelector(
    (state) => state.userRoleOnParticularProject
  );
  const { role } = userRoleOnProject;
  // External javascript imports

  useEffect(() => {
    document
      .querySelector(".openSearch")
      .addEventListener("click", function () {
        document.querySelector(".content-search").style.left =
          document.querySelector(".shorsearch").offsetLeft + "px";
        document.querySelector(".content-search").classList.remove("d-none");
        // if (wwidth <= 1285) document.querySelector(".content-search").style.right = "0";
      });
  }, []);

  // show action btn

  const [actionStyle, setActionStyle] = useState("btn-action-off");
  const [disabled, setDisabled] = useState(true);

  // Handle close create modal

  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleHideModalCreateModal = () => {
    setShowCreateModal(false);
  };

  // Show project list

  const [showActions, setShowActions] = useState(false);
  // Fetch projectlist
  const userLogin = useSelector((state) => state.userLogin);

  // fetching project list with react query
  const { isLoading } = useFetchProjectList();

  const [projectId, setProjectId] = useState();

  // local storage change
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  const createObs = useSelector((state) => state.createObservation);
  const { obsCreateLoading } = createObs;

  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  useEffect(() => {
    let projectId = localStorage.getItem("projectId");

    setProjectId(projectId);
    dispatch(
      observationTypesAction({
        observationTypesListFromReducer: [],
      })
    );
    dispatch(listProjects());
    dispatch(changeMarker({ id: "", index: "" }));
  }, [dispatch, userInfo, localProjectId]);

  // date range picker functions
  const [openCalendar, setOpenCalendar] = useState(false);
  // Set dates
  const [dates, setDates] = useState([]);
  // date range picker functions'
  const { setUserObsUpdateTracker } = userObservationsUpdateTracker();
  const [form] = Form.useForm();
  const onButton = () => {
    form.resetFields();
    setDates([]);
    setUserObsUpdateTracker(true);
    dispatch(
      datesRangesActions({
        dateRangesArray: [0, 0],
      })
    );
    setOpenCalendar(false);
  };
  const onChangeRangeDate = (value, dateString) => {
    if (value) {
      let rd = value.map((items) => {
        let m = dayjs(items).toISOString();

        return m;
      });
      const startOfDateRange = new Date(rd[0]);
      startOfDateRange.setHours(1, 0, 0, 0);
      const endOfDateRange = new Date(rd[1]);
      endOfDateRange.setHours(23, 59, 59, 999);

      const startTimestamp = startOfDateRange.getTime() / 1000;
      const endTimestamp = endOfDateRange.getTime() / 1000;
      setUserObsUpdateTracker(true);
      dispatch(
        datesRangesActions({
          dateRangesArray: [
            Math.round(startTimestamp),
            Math.round(endTimestamp),
          ],
        })
      );
      setDates(rd);
      setOpenCalendar(false);
    }
  };

  useEffect(() => {
    onChangeRangeDate();
  }, [dates]);

  // getting  id from lisviewtable
  const [idList, setIdList] = useState([]);

  const CallBack = useCallback(
    (childrenData) => {
      setIdList(childrenData);
      if (idList && idList.length !== 0) {
        setActionStyle("btn-action-on");
        setDisabled(false);
      } else {
        setActionStyle("btn-action-off");
        setDisabled(true);
        setShowActions(false);
      }

      return childrenData;
    },
    [idList]
  );

  // delete list of observations
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDeleteObservations = () => {
    dispatch(deleteListOfObservations(idList));
    dispatch(deleteObservationLocally(idList));
    // handleHideConfirmationModal();
    setShowConfirmModal(false);
  };

  // upload Observations To Inaturalist
  const handleUploadToINaturalist = () => {
    dispatch(uploadListOfObservationsToInaturalist(idList, rowData));
    setShowActions((i) => !i);
    // dispatch(deleteObservationLocally(idList));
  };
  const handleHideConfirmationModal = () => {
    setShowConfirmModal(false);
  };

  // Upload to iNaturalist nottifications

  // Fetch observations

  const singleObservationData = useSelector((state) => state.singleObsData);
  const { singleObsId } = singleObservationData;
  const updatedLocalProject = useSelector(
    (state) => state.updateProjectLocally
  );
  const { updtedObservationData } = updatedLocalProject;
  // fetch observaion with react query
  const {
    data,
    obsListLoading,
    isRefetchingObsList,
    isFetchingNextPage,
    queryRefetchValidObsList,
  } = useFetchObservaionList({ id: localProjectId });

  const { singleObs } = useFetchSingleObs({
    singleObsId,
  });
  useEffect(() => {
    if (uploadSuccessNotif) {
      queryRefetchValidObsList();
    }
  }, [uploadSuccessNotif, queryRefetchValidObsList]);

  const [rowData, setRowsData] = useState([]);

  // setting observtion List to a use state
  const [allValideObs, setAllValideObs] = useState([]);

  const { setCurrentPage, setTotalPageNumber } = usePatrolVariables(
    (state) => ({
      setTotalPageNumber: state.setTotalPageNumber,
      setCurrentPage: state.setCurrentPage,
    })
  );

  useEffect(() => {
    if (data) {
      setTotalPageNumber(Math.ceil(data.pages[0].param.nbPage));
      // setTotalObsNumber(data.pages[0].param.TotalCount)
    }
  }, [data]);

  useEffect(() => {
    const handleSetProjectObservation = () => {
      const obs =
        data &&
        data !== "undefined" &&
        data.pages.flatMap((page) => {
          return page.data;
        });
      setAllValideObs(obs);
      // setUpdateObsArray(obs);
      data && setCurrentPage(data.pageParams.length - 1);
    };
    handleSetProjectObservation();
  }, [data, isRefetchingObsList, updtedObservationData]);

  // edit obs list and add updated data
  useEffect(() => {
    if (updtedObservationData !== null) {
      // Iterate through the array using map
      if (updateObsSuccessNotif && updateObsSuccessNotif === true) {
        let uptObj = updtedObservationData;
        let updatedArray = allValideObs.map((obj) => {
          if (obj.id === uptObj.id) {
            return uptObj; // Replace with the separate object
          } else {
            return obj; // Keep the object as it is
          }
        });
        setAllValideObs(updatedArray);
      }
    }
  }, [updtedObservationData, updateObsSuccessNotif]);

  // update obs list and delete deleted obs
  const localDeleteId = useSelector((state) => state.deleteProjectLocally);
  const { deleteId } = localDeleteId;
  useEffect(() => {
    if (deleteId && deleteId.length !== 0) {
      if (deleteObsSuccessNotif && deleteObsSuccessNotif === true) {
        const results = allValideObs?.filter(
          (obs) => !deleteId.includes(obs.id)
        );

        setAllValideObs(results);
      }
    }
  }, [deleteId, deleteObsSuccessNotif]);

  useEffect(() => {
    if (singleObs && allValideObs !== "undefined" && updateObsSuccessNotif) {
      const obsData = singleObs.data;
      setAllValideObs((prev) =>
        prev?.map((item) => {
          if (item.id === obsData.id) {
            return obsData; // Replace the item with the previous object
          }
          return item; // Keep the item as it is
        })
      );
      dispatch(successNofit({ updateObsSuccessNotif: false }));
    }
  }, [singleObs, updateObsSuccessNotif]);

  useEffect(() => {
    setRowsData(allValideObs);
  }, [allValideObs]);

  return (
    <>
      <div
        className="container-fluid p-0"
        style={{
          overflowY: "hidden",
        }}
      >
        <div className="header-list-view">
          <Header />

          <div
            className="d-flex flex-column flex-shrink-0 menu-list position-absolute w-100 h-100 d-none"
            // style="z-index: 20;"
            style={{ zIndex: "20" }}
          >
            <div className="menu-close text-end p-2">
              <span className="close-menu">
                <span className="pe-sm-2">
                  <img
                    src="assets/elements/images/icon/close.svg"
                    alt=""
                    // style="height: 17px"
                    style={{ height: "17px " }}
                  />
                </span>
              </span>
            </div>
          </div>

          <div className="row head02 px-2  ">
            <div className="mobile-action-btn ">
              <div className="px-3 d-flex align-items-center relative justify-content-end mt-1 pt-1 act-cnt ">
                {role ? (
                  <button
                    type="button"
                    className={`  px-2 ${actionStyle} `}
                    disabled={disabled}
                    // btn-action-on

                    onClick={() => {
                      setShowActions(!showActions);
                    }}
                  >
                    Actions
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`  px-2 btn-action-off `}
                    disabled={disabled}
                    // btn-action-on

                    // onClick={() => {
                    //   setShowActions(!showActions);
                    // }}
                  >
                    Actions
                  </button>
                )}
                {showActions && !disabled && (
                  <div
                    className="actionLists"
                    style={{
                      zIndex: "10",
                      width: "120px",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      // positio: "fixed",
                    }}
                  >
                    {Number(localProjectId) === 39 && (
                      <>
                        {idList.length <= 5 && (
                          <span
                            className="d-block px-2 py-3 actionItem "
                            style={{
                              borderBottom: ".5px solid #f0f0f0",
                              background: "#B2DBFC",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleUploadToINaturalist();
                            }}
                          >
                            <span className="ms-2 me-sm-1 ">
                              <AiOutlineQuestionCircle
                                style={{
                                  fontSize: "20px",
                                  marginBottom: "2px",
                                }}
                              />
                            </span>
                            iNat ID
                          </span>
                        )}
                        {idList.length > 5 && (
                          <span
                            className="d-block px-2 py-3 actionItem "
                            style={{
                              borderBottom: ".5px solid #f0f0f0",
                              background: "#B2DBFC",
                              cursor: "pointer",
                            }}
                          >
                            <Popconfirm
                              placement="bottom"
                              title={"Warning"}
                              description={
                                "The limit for uploading observations at once to iNaturalist is 5. Reduce selected observations to maximum 5"
                              }
                              okText={"ok"}
                              showCancel={false}
                              okButtonProps={{
                                style: {
                                  backgroundColor: "#042b4a",
                                  color: "white",
                                },
                              }}
                            >
                              <span>
                                <span className="ms-2 me-sm-1 ">
                                  <AiOutlineQuestionCircle
                                    style={{
                                      fontSize: "20px",
                                      marginBottom: "2px",
                                    }}
                                  />
                                </span>
                                iNat ID
                              </span>
                            </Popconfirm>
                          </span>
                        )}
                      </>
                    )}
                    <span
                      className="d-block px-2 py-3 actionItem"
                      onClick={() => {
                        setShowConfirmModal(true);
                      }}
                    >
                      <span className="ms-2 me-sm-1">
                        <MdDelete
                          style={{
                            fontSize: "20px",
                            marginBottom: "2px",
                          }}
                        />
                      </span>{" "}
                      Delete
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div
              className="menu-button-bas align-items-stretch pointer px-0 "
              style={{ width: "100%" }}
            >
              <div className=" d-flex align-items-center borderright menelem bpro pointer ">
                <div
                  className="d-none content-projet "
                  style={{ zIndex: "10" }}
                >
                  <div className="text-end">
                    <span className="ps-2 pe-2  d-inline-block projet-close pointer ">
                      <img
                        src="assets/elements/images/icon/close.svg"
                        alt=""
                        style={{ height: "15px" }}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="px-4 d-flex align-items-center justify-content-center borderright flex-fill stafdate">
                <div className="px-2 d-flex align-items-center   flex-fill stafdate">
                  <span
                    className=" pe-sm-2 "
                    onClick={() => {
                      setOpenCalendar(!openCalendar);
                    }}
                  >
                    <img
                      src="assets/elements/images/icon/calander.svg"
                      alt=""
                      className="filtre"
                    />
                  </span>
                  <Form
                    form={form}
                    name="advanced_assessment_form"
                    className="ant-date-picker"
                  >
                    <Form.Item name="field">
                      <RangePicker
                        onClick={() => {
                          setOpenCalendar(true);
                        }}
                        className="dateRange"
                        placeholder={[
                          `${t("admin.exportData.startDate")}`,
                          `${t("admin.exportData.endDate")}`,
                        ]}
                        allowClear={true}
                        onChange={onChangeRangeDate}
                        clearIcon={null}
                        open={openCalendar}
                      ></RangePicker>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        onClick={onButton}
                        className={`${
                          dates.length === 0
                            ? "clear__filters__btn__disabled"
                            : "clear__filters__btn"
                        }`}
                        style={{ marginLeft: "3rem", width: "92px" }}
                      >
                        {t("clear")}
                      </Button>
                    </Form.Item>
                  </Form>
                  <div
                    className="cont-date d-none px-4 "
                    style={{ zIndex: "10" }}
                  >
                    <div className="text-end">
                      <span className="p-2  d-inline-block date-close pointer ">
                        <img
                          src="assets/elements/images/icon/close.svg"
                          alt=""
                          style={{ height: "5px" }}
                        />
                      </span>
                    </div>
                    <div className="text-center">
                      <div
                        id="datestart"
                        className="border-date d-inline-block"
                      ></div>
                      <div
                        id="dateend"
                        className="border-date d-inline-block"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className="cont-date d-none px-4 "
                  style={{ zIndex: "10" }}
                >
                  <div className="text-end">
                    <span className="p-2  d-inline-block date-close pointer ">
                      <img
                        src="assets/elements/images/icon/close.svg"
                        alt=""
                        // style="height: 15px"
                        style={{ height: "15px" }}
                      />
                    </span>
                  </div>
                  <div className="text-center">
                    <div
                      id="datestart"
                      className="border-date d-inline-block"
                    ></div>
                    <div
                      id="dateend"
                      className="border-date d-inline-block"
                    ></div>
                  </div>
                </div>
              </div>

              <div className="px-3 d-flex align-items-center justify-content-center shorsearch borderright">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="openSearch"
                >
                  <button
                    type="button"
                    className="btn btn-light btn-sort ml-auto "
                  >
                    <img src="assets/elements/images/icon/search2.svg" alt="" />
                  </button>
                  <span
                    className="ms-sm-1 search__text text-center  d-block"
                    style={{ fontSize: ".9rem" }}
                  >
                    {t("search")}
                  </span>
                </div>
              </div>

              <div className="px-4 d-flex align-items-center justify-content-center borderright ">
                <button
                  className={`new__obs__btn`}
                  style={{
                    cursor: `${obsCreateLoading ? "not-allowed" : "pointer"}`,
                  }}
                  disabled={obsCreateLoading}
                  onClick={() => {
                    setShowCreateModal(!showCreateModal);
                  }}
                >
                  <img src={plus} alt="add" width={"13px"} />
                  <span className="new__obs__text">{t("newObs1")}</span>
                </button>
              </div>

              <div className="px-3 d-flex align-items-center relative justify-content-center act-cnt">
                {role ? (
                  <button
                    type="button"
                    className={`  px-2 ${actionStyle} `}
                    disabled={disabled}
                    // btn-action-on

                    onClick={() => {
                      setShowActions(!showActions);
                    }}
                  >
                    Actions
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`  px-2 btn-action-off `}
                    disabled={disabled}
                    // btn-action-on

                    // onClick={() => {
                    //   setShowActions(!showActions);
                    // }}
                  >
                    Actions
                  </button>
                )}
                {showActions && !disabled && (
                  <div
                    className="actionLists"
                    style={{
                      zIndex: "10",
                      width: "120px",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      // positio: "fixed",
                    }}
                  >
                    {Number(localProjectId) === 39 && (
                      <>
                        {idList.length <= 5 && (
                          <span
                            className="d-block px-2 py-3 actionItem "
                            style={{
                              borderBottom: ".5px solid #f0f0f0",
                              background: "#B2DBFC",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleUploadToINaturalist();
                            }}
                          >
                            <span className="ms-2 me-sm-1 ">
                              <AiOutlineQuestionCircle
                                style={{
                                  fontSize: "20px",
                                  marginBottom: "2px",
                                }}
                              />
                            </span>
                            iNat ID
                          </span>
                        )}
                        {idList.length > 5 && (
                          <span
                            className="d-block px-2 py-3 actionItem "
                            style={{
                              borderBottom: ".5px solid #f0f0f0",
                              background: "#B2DBFC",
                              cursor: "pointer",
                            }}
                          >
                            <Popconfirm
                              placement="bottom"
                              title={"Warning"}
                              description={
                                "The limit for uploading observations at once to iNaturalist is 5. Reduce selected observations to maximum 5"
                              }
                              okText="ok"
                              showCancel={false}
                            >
                              <span>
                                <span className="ms-2 me-sm-1 ">
                                  <AiOutlineQuestionCircle
                                    style={{
                                      fontSize: "20px",
                                      marginBottom: "2px",
                                    }}
                                  />
                                </span>
                                iNat ID
                              </span>
                            </Popconfirm>
                          </span>
                        )}
                      </>
                    )}
                    <span
                      className="d-block px-2 py-3 actionItem"
                      onClick={() => {
                        setShowConfirmModal(true);
                      }}
                    >
                      <span className="ms-2 me-sm-1">
                        <MdDelete
                          style={{
                            fontSize: "20px",
                            marginBottom: "2px",
                          }}
                        />
                      </span>
                      Delete
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <main style={{ zIndex: "2" }} className="main main-list-view">
          {isLoading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",

                transform: "translate(-50%, -50%)}",
              }}
            >
              <Loading />
            </div>
          ) : (
            <ListViewTable
              handleCallback={CallBack}
              projectId={projectId}
              dates={dates}
              obsData={rowData}
              obsListLoading={obsListLoading}
              isRefetching={isRefetchingObsList}
              isFetchingNextPage={isFetchingNextPage}
              selectedProject={setProjectId}
            />
          )}
        </main>

        {/* Create observation modal */}
        {showCreateModal && (
          <CreateObservation
            handleHideModalCreateModal={handleHideModalCreateModal}
            selectedProject={setProjectId}
            projectId={projectId}
          />
        )}
      </div>

      {showConfirmModal && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineInfoCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={"Delete Items ?"}
          details={"Items will be deleted. This action cannot be undone."}
          btn1Text={"Cancel"}
          btn2Text={"Delete "}
          color={"#404040"}
          handleHideConfirmationModal={handleHideConfirmationModal}
          handleConfirmAction={handleDeleteObservations}
          closeConfirm={handleHideConfirmationModal}
        />
      )}
    </>
  );
}

export default ListView;
