import React, { useState, useEffect, useRef } from "react";
import LeafletMap from "../../../components/map/leafletMap/LeafletMap";
import { motion } from "framer-motion";
import "../../map/MapContainer.css";
import searchIcon from "../../../assets/images/icon/search2.svg";
import { useDispatch } from "react-redux";
import {
  changeMarker,
  observationTypesAction,
  openPopup,
  datesRangesActions,
} from "../../../actions/projectActions";

import MapSidebar from "../../../components/map/mapslider/MapSidebar";
import Legend from "../../../components/map/Legend";
import close from "../../../assets/images/close.svg";

import centerMapBtn from "../../../assets/images/centerMapBtn.svg";

// Query all data

// date range picker imports
import { Button, DatePicker, Form } from "antd";
import Filter from "../../../components/MapFilters/Filter";

import Header from "../../../components/Header/Header";
import Loading from "../../../components/LoadingError/Loading";
import { uncheckAllAction } from "../../../services/rxjs";
import { RxCross2 } from "react-icons/rx";
import Loading2 from "../../../components/LoadingError/Loading2";
import Select from "react-select";
import InfiniteScroll from "../../../components/map/mapslider/InfiniteScroll";

import { useTranslation } from "react-i18next";
import { useGetCountryCoordinates } from "../../../utils/useGetCoutryCoordinates";
import VIPHeader from "../../../components/vip/header/VIPHeader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  useFetchVIPMiniCardObservaionList,
  useFetchVIPObservaionList,
} from "../../../queries/useFetchVIPData";
import { useFetchVIPFilteredObsertionTypeList } from "../../../queries/useFetchVIPOptions";

const { RangePicker } = DatePicker;
const VIPMapView = () => {
  // center map on click
  const [toggleMapCcenter, setToggleMapCcenter] = useState(false);

  const handleCenterMap = () => {
    setToggleMapCcenter((val) => !val);

    setTimeout(() => {
      setToggleMapCcenter((val) => !val);
    }, 2000);
  };

  // Get project Coordinates
  const { countryLat, countryLong, loadingCountryCoords } =
    useGetCountryCoordinates();

  // Translate switcher
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [projectID, setProjectID] = useState(null);

  useEffect(() => {
    const currentUrl = location.pathname;
    const match = currentUrl.match(/vid=([^&]+)/);
    const vid = match ? match[1] : null;
    setProjectID(vid);
  }, [location.pathname]);

  const {
    data,
    obsListLoading,
    isRefetchingNextPage,
    isRefetchingObsList,
    obsFetchError,
    fetchNextPage,
    queryRefetchValidObsList,
  } = useFetchVIPObservaionList({ id: localStorage.getItem("vid_projectId") });

  useEffect(() => {
    queryRefetchValidObsList();
  }, [queryRefetchValidObsList]);

  const [page, setPage] = useState(0);

  // Refetch commented

  useEffect(() => {
    if (data && data !== "undefined") {
      if (data && data.pages[0].param.Page > page) {
        setPage(page + 1);
        fetchNextPage();
      }
    }
  }, [data]);

  const dispatch = useDispatch();

  const [initialObsState, setInitialObsState] = useState([]);
  const [observationList, setObservationList] = useState([]);

  // setting observtion to a use state to facilitate sorting
  const handleSetProjectObservation = () => {
    const obs =
      data &&
      data !== "undefined" &&
      data.pages.flatMap((page) => {
        return page.data;
      });

    setObservationList(obs);
    setInitialObsState(obs);
  };

  useEffect(() => {
    handleSetProjectObservation();
  }, [data, isRefetchingObsList]);
  useEffect(() => {
    const bsearchButton = document.querySelector(".bsearch");
    const contentSearch = document.querySelector(".content-search");
    const closeButtonList = document.querySelectorAll(".sort-close");

    if (bsearchButton && contentSearch && closeButtonList) {
      bsearchButton.addEventListener("click", function () {
        contentSearch.style.top =
          document.querySelector(".shorsearch").offsetHeight + 2 + "px";
        contentSearch.classList.remove("d-none");
        uncheckAllAction();
        setFilterObs([]);
        if (window.innerWidth <= 1285) {
          contentSearch.style.right = "20px";
        }
      });

      closeButtonList.forEach(function (element) {
        element.addEventListener("click", function () {
          dispatch(changeMarker({ id: "", index: "" }));
          dispatch(openPopup({ id: "", index: "" }));
          // setSelectedObstTYpes(null);
          // dispatch(
          //   observationTypesAction({
          //     observationTypesListFromReducer: [],
          //   })
          // );
          // setObservationList(initialObsState);
          this.parentElement.parentElement.classList.add("d-none");
        });
      });
    }
  }, [dispatch]);


  // open and close project list

  // local storage change

  // Hide legend
  const [showLegend, setShowLegend] = useState(false);
  const hideLegend = (data) => {
    setShowLegend(data);
  };

  // setting observtion to a use state to facilitate sorting

  // Update obs list locally

  // Get updated obs and update  array

  // }, [updatedObservation, observationList]);

  // show map sidebar and sort container
  const [showMapSlider, setShowMapSlider] = useState(true);
  const [showSort, setShowSort] = useState(false);
  const handleShowSidebar = () => {
    if (showMapSlider) {
      setShowMapSlider(false);
      setShowSort(false);
    } else {
      setShowMapSlider(true);
    }
  };

  const handleShowSort = () => {
    if (showMapSlider) {
      setShowSort(true);
    } else {
      setShowSort(false);
    }
  };

  const sortByDate = () => {
    dispatch(changeMarker({ id: "", index: "" }));
    if (miniCardObservationList) {
      let sortedObservationByDate = miniCardObservationList.sort((obs1, obs2) =>
        obs1.date.date > obs2.date.date
          ? 1
          : obs1.date.date < obs2.date.date
          ? -1
          : 0
      );
      setMiniCardObservationList(sortedObservationByDate);
    }
  };

  // Sorting data by Id and name

  // Sorting data
  function sortByID() {
    dispatch(changeMarker({ id: "", index: "" }));
    if (miniCardObservationList) {
      let sortedObservationById = miniCardObservationList.sort((obs1, obs2) =>
        obs1.id > obs2.id ? -1 : obs1.id < obs2.id ? 1 : 0
      );

      setMiniCardObservationList(sortedObservationById);
    }
  }

  // sorting by name
  function sortByName() {
    dispatch(changeMarker({ id: "", index: "" }));
    if (miniCardObservationList) {
      let sortedObservationByName = miniCardObservationList.sort(
        (obs1, obs2) => {
          let fa = obs1.TypeObservation.toLowerCase(),
            fb = obs2.TypeObservation.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        }
      );
      setMiniCardObservationList(sortedObservationByName);
    }
  }

  // search in observation list

  // Filter by date range

  // filter observation based on date
  const [openCalendar, setOpenCalendar] = useState(false);
  const [form] = Form.useForm();

  // reset date range picker
  const onButton = () => {
    dispatch(changeMarker({ id: "", index: "" }));
    dispatch(
      datesRangesActions({
        dateRangesArray: [0, 0],
      })
    );
    form.resetFields();
    setDates([]);
    // handleFilterByObservations(filterObs);
    setOpenCalendar(false);
    queryRefetchValidObsList();
  };

  // date range picker functions
  const [dates, setDates] = useState([]);
  function filterObservations(datesArray) {
    dispatch(changeMarker({ id: "", index: "" }));
    if (datesArray) {
      setOpenCalendar(false);
      setDates(datesArray, "dates Arrays");

      const startOfDateRange = new Date(datesArray[0]);
      startOfDateRange.setHours(1, 0, 0, 0);
      const endOfDateRange = new Date(datesArray[1]);
      endOfDateRange.setHours(23, 59, 59, 999);

      const startTimestamp = startOfDateRange.getTime() / 1000;
      const endTimestamp = endOfDateRange.getTime() / 1000;

      dispatch(
        datesRangesActions({
          dateRangesArray: [
            Math.round(startTimestamp),
            Math.round(endTimestamp),
          ],
        })
      );
    }
  }

  // Left filters
  const [showFilters, setShowFilters] = useState(false);
  const [observationNumber, setObservationNumber] = useState();
  const [leftFilterWidth, setLeftFilterWidth] = useState(0);
  const [addBtnPosition, setAddBtnPosition] = useState(20);
  const [legendBtnPosition, setLegendBtnPosition] = useState(50);
  const [closeFilterBtnPosition, setCloseFilterBtnPosition] = useState(-100);

  const ref1 = useRef(null);

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
    if (showFilters) {
      setAddBtnPosition(20);
      setLegendBtnPosition(50);
      setCloseFilterBtnPosition(-100);
    } else {
      setCloseFilterBtnPosition(leftFilterWidth - 5);
      setAddBtnPosition(leftFilterWidth - 20);
      setLegendBtnPosition(leftFilterWidth + 30);
    }
  };

  // pointer style
  const leftFilterPointer = {
    marginLeft: "10px",
    display: "inline-block",

    transition: "0.3s ease-in-out",
  };
  const leftFilterPointerOpen = {
    marginLeft: "10px",
    transform: "rotate(90deg)",
    display: "inline-block",
    transition: "0.3s ease-in-out",
  };

  useEffect(() => {
    if (ref1 && ref1.current !== null) {
      setLeftFilterWidth(ref1.current.offsetWidth);
    }

    let totalObs = initialObsState && initialObsState.length;
    setObservationNumber(totalObs);
  }, [initialObsState]);
  // observationList
  // Search box

  //===== filter by observation type=====

  // 1) get all observations types from the list of all observations
  const [observetionTypeList, setObservetionTypeList] = useState();

  const [newObsListArray, setNewObsListArray] = useState([]);

  //2) set the observations types to an array
  useEffect(() => {
    if (!Array.isArray(initialObsState)) {
      return;
    }
    const uniqueTypes = [
      ...new Set(initialObsState.map((item) => item.TypeObservation)),
    ];
    setObservetionTypeList(uniqueTypes);
  }, [initialObsState]);

  // 3) build the object with observations type to insert in the filter components as a prop.

  // fetch filter observation type list

  const { FilterObsList, refetchFilterObs } =
    useFetchVIPFilteredObsertionTypeList();

  useEffect(() => {
    refetchFilterObs();
    if (FilterObsList) {
      setFilterObsList(FilterObsList);
    }
  }, [FilterObsList, refetchFilterObs, initialObsState, data]);

  const [filterObsList, setFilterObsList] = useState(FilterObsList);
  const [obsTypesForSearch, setObsTypesForSearch] = useState([]);

  useEffect(() => {
    let i =
      filterObsList &&
      filterObsList.map((item, i) => {
        return { id: item.id, title: item.name, children: [] };
      });
    setNewObsListArray(i);

    let b =
      filterObsList &&
      filterObsList.map((item, i) => {
        return { value: item.id, label: item.name };
      });
    setObsTypesForSearch(b);
  }, [FilterObsList, filterObsList, observationList, refetchFilterObs]);

  // 4) get the value of the obs type when checked on the UI.
  const [filterObs, setFilterObs] = useState([]);
  const [obsTypesId, setObsTypesId] = useState([]);
  const [obsTypeSelectedForSearch, setObsTypeSelectedForSearch] = useState([]);
  const [obsTypeList, setObsTypeList] = useState([]);
  const handleContent = (index) => {
    form.resetFields();
    const newObsType = newObsListArray[index].title;

    if (obsTypeSelectedForSearch.includes(newObsType)) {
      // If it exists, remove it
      const updatedFilterObs = obsTypeSelectedForSearch.filter(
        (item) => item !== newObsType
      );

      setObsTypeSelectedForSearch(updatedFilterObs);
    } else {
      // If not, add it
      setObsTypeSelectedForSearch((prevState) => [...prevState, newObsType]);
    }

    const itemId = newObsListArray[index].id;
    // Check if title already exists in filterObs
    if (obsTypesId.includes(itemId)) {
      // If it exists, remove it
      const updatedObsTypesId = obsTypesId.filter((item) => item !== itemId);
      setObsTypesId(updatedObsTypesId);
    } else {
      // If not, add it
      setObsTypesId((prevState) => [...prevState, itemId]);
    }
  };

  useEffect(() => {
    dispatch(
      observationTypesAction({
        observationTypesListFromReducer: obsTypesId,
      })
    );
  }, [obsTypesId, dispatch]);

  useEffect(() => {
    setObservationNumber(observationList && observationList.length);
  }, [observationList]);

  // un used code end============================

  const header01Ref = useRef();
  const headerBottomRef = useRef();

  const [headerBottomHeight, setHeaderBottomHeight] = useState();

  useEffect(() => {
    if (headerBottomRef && headerBottomRef.current) {
      setHeaderBottomHeight(headerBottomRef.current.offsetHeight);
    }
  }, [headerBottomRef]);

  // const { ref, inView } = useInView();
  const [miniCardObservationList, setMiniCardObservationList] = useState([]);
  const {
    miniCardData,
    miniCardHasNextPage,
    miniCardFetchNextPage,
    miniCardIsFetchingNextPage,
    miniCardIsRefetcing,

    miniCardObsLoading,
    miniCardObsFetchError,
  } = useFetchVIPMiniCardObservaionList({
    id: localStorage.getItem("vid_projectId"),
  });
  const [miniCardpage, setMinicardPage] = useState(1);
  // setting observtion to a use state to facilitate sorting
  const handleSetMiniCardtObservation = () => {
    const obs =
      miniCardData &&
      miniCardData?.pages &&
      miniCardData.pages.flatMap((page) => {
        return page.data;
      });

    setMiniCardObservationList(obs);
  };

  useEffect(() => {
    handleSetMiniCardtObservation();
  }, [miniCardData, miniCardIsRefetcing]);

  const [fetchNextPageLoader, setFetchNextPageLoader] = useState(false);

  useEffect(() => {
    if (miniCardData && miniCardData !== "undefined") {
      if (miniCardData.pages[0].param.Page > miniCardpage) {
        setMinicardPage(miniCardpage + 1);
        setFetchNextPageLoader(true);
      } else {
        setFetchNextPageLoader(false);
      }
    }
  }, [miniCardData]);

  // update mini card list Locally  after an obs is created

  // search
  const [selectedObstTYpes, setSelectedObstTYpes] = useState(null);

  const handleClear = () => {
    setSelectedObstTYpes(null);
    dispatch(
      observationTypesAction({
        observationTypesListFromReducer: [0],
      })
    );
  };

  useEffect(() => {
    if (selectedObstTYpes) {
      setObsTypeList([selectedObstTYpes.value]);
    }
  }, [selectedObstTYpes]);

  // fetch Single obs to update curret array

  if (obsFetchError || miniCardObsFetchError) {
    return (
      <div>
        <Header />
        <div
          style={{
            width: "100%",
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4>Something Went Wrong. Please reload page later</h4>
        </div>
      </div>
    );
  }
  return (
    <div
      className="body"
      style={{
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        width: "100%",
      }}
    >
      <div className="container-fluid p-0">
        <div
          ref={header01Ref}
          onClick={() => {
            setShowSort(false);
          }}
        >
          <VIPHeader />
        </div>

        {/* btn-filter */}
        <div className="row head02 px-2" ref={headerBottomRef}>
          <div className="menubas__mobile">
            <div className="mobile__menu__container">
              <div className="mobile__filter">
                <span
                  onClick={() => {
                    handleShowFilters();
                  }}
                >
                  <img
                    src="assets/elements/images/icon/filter.svg"
                    alt=""
                    className="filtre"
                  />
                  ({observationNumber})
                  <span
                    className=""
                    style={
                      !showFilters ? leftFilterPointer : leftFilterPointerOpen
                    }
                  >
                    <img
                      src="assets/elements/images/icon/arrowright.svg "
                      alt=""
                    />
                  </span>
                </span>
              </div>
              <div>
                <div
                  className=""
                  onClick={() => {
                    handleShowSidebar();
                  }}
                >
                  <span className="pxh-3">
                    <img
                      src="assets/elements/images/icon/list2.svg"
                      alt=""
                      width={20}
                    />
                  </span>
                  <span className="ms-sm-1 me-sm-4 positxt  ">
                    Observations
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="menu-button-bas align-items-stretch pointer px-0 ">
            <div className="mobile__filter">
              <span
                onClick={() => {
                  handleShowFilters();
                }}
              >
                <img
                  src="assets/elements/images/icon/filter.svg"
                  alt=""
                  className="filtre"
                />
              </span>
            </div>
            <div
              className="staffiltre  d-flex align-items-center borderright menelem filter_desktop"
              ref={ref1}
              onClick={() => {
                handleShowFilters();
              }}
            >
              <div className="px-3">
                <div>
                  <span>
                    <img
                      src="assets/elements/images/icon/filter.svg"
                      alt=""
                      className="filtre"
                    />
                  </span>
                  <span className="ms-sm-1 filter-txt ">
                    {t("filters")} ({observationNumber} observations)
                  </span>
                  <span
                    className=""
                    style={
                      !showFilters ? leftFilterPointer : leftFilterPointerOpen
                    }
                  >
                    <img
                      src="assets/elements/images/icon/arrowright.svg "
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </div>

            {/* here */}

            <div
              className={"content-projets-hide "}
              style={{ zIndex: "1" }}
            ></div>

            <div className="px-4 d-flex align-items-center  borderright flex-fill stafdate">
              {/* <div className="calander-mobile">
                <span className="pe-sm-2 ">
                  <img
                    src="assets/elements/images/icon/calander.svg"
                    alt=""
                    className="filtre"
                  />
                </span>
              </div> */}
              <span
                className=" pe-sm-2 "
                onClick={() => {
                  setOpenCalendar(!openCalendar);
                }}
              >
                <img
                  src="assets/elements/images/icon/calander.svg"
                  alt=""
                  className="filtre"
                />
              </span>
              <Form
                form={form}
                name="advanced_assessment_form"
                className="ant-date-picker"
              >
                <Form.Item name="field">
                  <RangePicker
                    onClick={() => {
                      setOpenCalendar(true);
                    }}
                    allowClear={true}
                    onChange={(e) => {
                      filterObservations(e);
                      // setDates(e);
                    }}
                    placeholder={[
                      `${t("admin.exportData.startDate")}`,
                      `${t("admin.exportData.endDate")}`,
                    ]}
                    clearIcon={null}
                    open={openCalendar}
                  ></RangePicker>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    onClick={onButton}
                    className={`${
                      dates.length === 0
                        ? "clear__filters__btn__disabled"
                        : "clear__filters__btn"
                    }`}
                    style={{ marginLeft: "4rem", width: "92px" }}
                  >
                    {t("clear")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div
              className="px-3 d-flex align-items-center justify-content-center shorsearch"
              // ref={ref2}
            >
              <div
                className=""
                onClick={() => {
                  handleShowSidebar();
                }}
              >
                <span className="pxh-3">
                  <img
                    src="assets/elements/images/icon/list2.svg"
                    alt=""
                    width={20}
                  />
                </span>
                <span className="ms-sm-1 me-sm-4 positxt  ">Observations</span>
              </div>

              <button
                type="button"
                className="btn btn-md btn-light btn-sort ml-auto bsearch"
              >
                <img src={searchIcon} alt="" />
              </button>
              <button
                type="button"
                className="btn  btn-light btn__sort ml-auto"
                onClick={() => {
                  // setShowSort(!showSort);
                  handleShowSort();
                }}
              >
                {t("sort")}
                <img
                  src="assets/elements/images/icon/sort.svg"
                  alt=""
                  className="ms-sm-2 hide-sort-arrow"
                />
              </button>
              <div
                className="content-search d-none ps-3 pe-3 pb-5  list-v"
                style={{
                  zIndex: "10",
                  top: "41px",
                  left: "-20px",
                  // marginRight: "100px",
                }}
              >
                <div
                  className="d-flex py-2"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="title-men   ">
                    {t("listview.customComponents.search")}
                  </div>
                  <span className=" sort-close pointer ">
                    <img src={close} alt="" style={{ height: "15px" }} />
                  </span>
                </div>

                <div className=" search__container">
                  {/* <input
                    name="my-search"
                    type="search"
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{ width: 229 }}
                  /> */}
                  {/* <div className="mt-3 mb-2  position-relative"> */}
                  <Select
                    options={obsTypesForSearch}
                    placeholder={`${t("listview.customComponents.search2")}`}
                    value={selectedObstTYpes}
                    onChange={(choice) => setSelectedObstTYpes(choice)}
                    // defaultValue={}
                    className="search__select"
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />
                  {/* </div> */}
                  <span className="clear__search" onClick={handleClear}>
                    {t("listview.customComponents.clear")}
                  </span>
                </div>
                <div className="search__list"></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="search__btn"
                    onClick={() => {
                      dispatch(
                        observationTypesAction({
                          observationTypesListFromReducer: obsTypeList,
                        })
                      );
                      // closeSearch();
                    }}
                  >
                    {t("listview.customComponents.search2")}
                  </div>
                </div>
              </div>

              {/* <div className="content-sort d-none z-11"> */}
              {showSort && (
                <div className="sort__by__container  z-11 ">
                  <div className=" sort__header ">
                    <div
                      style={{
                        color: "#073B60",
                        letterSpacing: "1px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("listview.customComponents.sortBy")}
                    </div>
                  </div>
                  <div
                    className=" sort__items "
                    onClick={() => {
                      sortByID();
                      // dispatch(changeMarker({ id: "", index: "" }));
                    }}
                  >
                    <a
                      href="#"
                      // className=" sort__items "
                    >
                      {t("listview.customComponents.idNumber")}
                    </a>
                  </div>
                  <div
                    className=" sort__items "
                    onClick={() => {
                      sortByName();
                      // dispatch(changeMarker({ id: "", index: "" }));
                    }}
                  >
                    <a
                      href="#"
                      // className=" sort__items "
                    >
                      {t("listview.customComponents.obsType")}
                    </a>
                    <div></div>
                  </div>
                  <div
                    className=" sort__items "
                    onClick={() => {
                      sortByDate();
                      // dispatch(changeMarker({ id: "", index: "" }));
                    }}
                  >
                    <a
                      href="#"
                      // className=" sort__items "
                    >
                      Date
                    </a>
                  </div>
                  <div
                    className="sort__close__btn pe-1 "
                    onClick={() => {
                      setShowSort(false);
                    }}
                  >
                    <img src={close} alt="" width={15} />
                  </div>
                </div>
              )}
              {/* </div> */}

              <motion.span
                whileInView={{ x: [100, -0] }}
                transition={{ duration: 0.3 }}
                className="d-inline-block obs-close pointer d-none z-9 mt-2"
              >
                <img
                  src="assets/elements/images/close3.png"
                  alt=""
                  style={{ height: "20px" }}
                />
              </motion.span>
              <motion.div
                // whileInView={{ x: [100, -0] }}
                // transition={{ duration: 0.3 }}

                className={`${
                  showMapSlider
                    ? "mapslider__container"
                    : "mapslider__container_hide"
                }`}
                style={{
                  top: `${headerBottomHeight + 1}px`,
                  // width: `${searchrWidth + 2}px`,
                  // `${leftFilterWidth}px`
                }}
              >
                {/* project list */}
                <div
                  className={`${
                    showMapSlider
                      ? "mapSlider__close_btn"
                      : "mapSlider__close_btn_hide"
                  }`}
                  onClick={() => {
                    // setShowMapSlider(false);
                    handleShowSidebar();
                  }}
                >
                  <img
                    src="assets/elements/images/close3.png"
                    alt=""
                    style={{ height: "20px" }}
                  />
                </div>
                <>
                  {miniCardObsLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Loading />
                    </div>
                  ) : (
                    <InfiniteScroll
                      fetchNextPage={miniCardFetchNextPage}
                      isLoadingNextPage={false}
                      fetchNextPageLoader={fetchNextPageLoader}
                    >
                      <MapSidebar
                        observationList={
                          // sirenInterNationalId !== Number(localProjectId)
                          //   ? miniCardObservationList
                          //   : []
                          miniCardObservationList
                        }
                        miniCardIsRefetcing={miniCardIsRefetcing}
                        // fetchNextPageLoader={fetchNextPageLoader}
                      />
                    </InfiniteScroll>
                  )}
                </>
              </motion.div>
            </div>
          </div>
        </div>
        <main style={{ zIndex: "2" }} className="main">
          <>
            <span className="pe-sm-2 filter-close d-none">
              <img
                src="assets/images/icon/close.png"
                alt=""
                style={{ height: "25px" }}
              />
            </span>
          </>

          <div
            style={{
              left: `${
                // closeFilterBtnPosition - 10
                showFilters ? "235px" : "-235px"
              }`,
            }}
            onClick={() => {
              handleShowFilters();
            }}
            className={`closeFilterBtn ${
              showFilters
                ? "mobile__show__closeFilterBtn"
                : "mobile__hide__filterBtn"
            }`}
          >
            <img
              src="assets/elements/images/close2.png "
              alt=""
              style={{}}
              width={20}
            />
          </div>

          <div
            style={{
              left: `${
                showFilters
                  ? "250px"
                  : // closeFilterBtnPosition + 10
                    "20px"
                // closeFilterBtnPosition + 140
              }`,
            }}
            className="selectedFilters"
          >
            <div className="selected__filter__obs__list">
              {obsTypeSelectedForSearch &&
                obsTypeSelectedForSearch.map((obs, i) => (
                  <div className="selectedFilter" key={i}>
                    {obs}
                    <span className="cancelFilter">
                      <RxCross2 />
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <div
            className="btn-center vip alignCC"
            style={{
              // left: `${addBtnPosition}px`,
              left: `${showFilters ? "230px" : "30px"}`,
              zIndex: "100",
            }}
            onClick={() => {
              handleCenterMap();
            }}
          >
            <img src={centerMapBtn} alt="add" width={"25px"} />
          </div>

          <div className="map__filters">
            <div
              className={`${
                showFilters
                  ? "map__filters__container"
                  : "map__filters__container__hide"
              }`}
              style={
                {
                  // width: `${leftFilterWidth}px`,
                }
              }
            >
              <div className="mt-3"></div>

              {/* if the child parameter is empty here is the bad use */}
              {/* {newObsListArray && ( */}
              <Filter
                title={`${t("obsType")}`}
                pdg={5}
                child={newObsListArray}
                nivo={1}
                getContent={handleContent}
                pid={2}
                contentParent={false}
              />
              {/* )} */}

              {/* if the child parameter is empty use this here */}
              {/* <Filter
                title="Status"
                pdg={5}
                nivo={1}
                pid={3}
                contentParent={false}
              /> */}

              <div
                className=" text-center clear__filter__container"
                style={{
                  width: "100%",
                }}
              >
                <hr />

                <button
                  onClick={() => {
                    uncheckAllAction();
                    setObsTypeSelectedForSearch([]);
                    setFilterObs([]); //not relevant anymore. some checks needs to be done before deleting this line
                    dispatch(
                      observationTypesAction({
                        observationTypesListFromReducer: [0],
                      })
                    );
                  }}
                  type="button"
                  className="btn btn-light mb-3 clear__filter__btn "
                  // onClick={() => {
                  //   handleClearFilters();
                  // }}
                >
                  {t("clearFilers")}
                </button>
              </div>
            </div>
          </div>

          <div className="map w-100  ">
            <span
              onClick={() => {
                setShowLegend(!showLegend);
              }}
              className="position-absolute btn-legend "
              style={{
                left: `${showFilters ? "270px" : "60px"}`,
                // `${legendBtnPosition}px`,
                zIndex: "2222",
              }}
            >
              <span className="men-text fw-bold pointer">{t("legend")}</span>
            </span>

            {showLegend && (
              <Legend
                hideLegend={hideLegend}
                legendPostion={legendBtnPosition}
              />
            )}

            <div
              className="w-100  "
              // hide search view
              onClick={() => {
                setShowSort(false);
              }}
            >
              {obsListLoading || loadingCountryCoords ? (
                <Loading2 />
              ) : (
                <div
                  style={{
                    opacity: "1",
                    zIndex: "9999",
                  }}
                >
                  <LeafletMap
                    countryLat={countryLat}
                    countryLong={countryLong}
                    observationList={observationList}
                    loading={obsListLoading}
                    refetchLoading={isRefetchingObsList}
                    loadingCountryCoords={loadingCountryCoords}
                    projectFetchLoading={false}
                    toggleMapCcenter={toggleMapCcenter}
                  />
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default VIPMapView;
