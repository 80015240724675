import React from "react";
import { useTranslation } from "react-i18next";

const GetQuestionAnswer = React.memo(({ data, questionTitle }) => {
  const { t } = useTranslation();
  const questionAnswer = data?.questionsAnwsers?.find(
    (qa) => qa.question.title === questionTitle
  );

  let translatedContent = questionAnswer?.content || "";

  // Translation logic based on questionTitle
  if (questionTitle === "Nest Status") {
    if (
      translatedContent === "Nid non derangé" ||
      translatedContent === "Nest Undisturbed"
    ) {
      translatedContent = t("qa.nest.nestUndisturbed"); // Fetch from en.json
    } else if (
      translatedContent === "Nest undisturbed lost" ||
      translatedContent === "Nid perdu sans être dérangé"
    ) {
      translatedContent = t("qa.nest.nestUndisturbedLost"); // Fetch from fr.json
    } else if (
      translatedContent === "Nest partially lost" ||
      translatedContent === "Nid en parti perdu"
    ) {
      translatedContent = t("qa.nest.nestPartiallyLost"); // Fetch from fr.json
    } else if (
      translatedContent === "Nest Totally Lost" ||
      translatedContent === "Nid totallement perdu"
    ) {
      translatedContent = t("qa.nest.nestTotallyLost"); // Fetch from fr.json
    } else {
      return translatedContent;
    }
  }

  return <div>{translatedContent}</div>;
});

export default GetQuestionAnswer;

// import React from "react";
// import { useTranslation } from "react-i18next";

// const GetQuestionAnswer = ({ data, questionTitle }) => {
//   const { t } = useTranslation();

//   const questionAnswer = data?.questionsAnwsers?.find(
//     (qa) => qa.question.title === normalizedTitle
//   );

//   let translatedContent = questionAnswer?.content || "";

//   // Translation logic based on nest status
//   if (questionAnswer?.question.title === "Nest Status") {
//     if (
//       translatedContent === "Nid non derangé" ||
//       translatedContent === "Nest Undisturbed"
//     ) {
//       translatedContent = t("qa.nest.nestUndisturbed");
//     } else if (
//       translatedContent === "Nest undisturbed lost" ||
//       translatedContent === "Nid perdu sans être dérangé"
//     ) {
//       translatedContent = t("qa.nest.nestUndisturbedLost");
//     } else if (
//       translatedContent === "Nest partially lost" ||
//       translatedContent === "Nid en parti perdu"
//     ) {
//       translatedContent = t("qa.nest.nestPartiallyLost");
//     } else if (
//       translatedContent === "Nest totally lost" ||
//       translatedContent === "Nid totallement perdu"
//     ) {
//       translatedContent = t("qa.nest.nestTotallyLost");
//     }
//   }

//   return (
//     <div>
//       {translatedContent.charAt(0).toUpperCase() + translatedContent.slice(1) ||
//         ""}
//     </div>
//   );
// };

// export default GetQuestionAnswer;
