import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";

const ImageCarosel = ({ images, hideImageCarosel, setShowDetailModal }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [imagesList, setImagesList] = useState([]);

  const getImgList = useCallback(() => {
    const tempList = [];
    if (images.img1 !== "NULL") {
      tempList.push(images.img1);
    }
    if (images.img2 !== "NULL") {
      tempList.push(images.img2);
    }
    if (images.img3 !== "NULL") {
      tempList.push(images.img3);
    }
    if (images.img4 !== "NULL") {
      tempList.push(images.img4);
    }
    setImagesList(tempList);
  }, [images]);

  useEffect(() => {
    getImgList();
  }, [getImgList]);

  const nextImage = () => {
    if (imageIndex !== imagesList.length - 1) {
      setImageIndex((value) => value + 1);
    }
  };

  const prevImage = () => {
    if (imageIndex !== 0) {
      setImageIndex(imageIndex - 1);
    }
  };

  return (
    <div>
      <div>
        <Modal
          show={true}
          onHide={hideImageCarosel}
          backdrop="static"
          centered
          className="mx-auto modalImgBody "
          keyboard={false}
          size="xl"
          dialogClassName=""
          aria-labelledby="example-custom-modal-styling-title"
        >
          <div>
            <div className="text-end">
              <span className="pe-sm-2 pointer" onClick={ () => {
                hideImageCarosel();
                setShowDetailModal(true);
                }}>
                <img
                  src="assets/elements/images/crossArrow.png"
                  alt=""
                  width={30}
                  style={{ cursor: "pointer" }}
                />
              </span>
            </div>

            <Modal.Body>
              <div className="d-flex align-items-center justify-content-center">
                {imageIndex !== 0 ? (
                  <div onClick={prevImage}>
                    <img
                      style={{ cursor: "pointer" }}
                      src={`assets/elements/images/leftChevron.png`}
                      alt="single_photo"
                      width={30}
                    />
                  </div>
                ) : (
                  <div onClick={prevImage}>
                    <img
                      style={{ cursor: "pointer", visibility: "hidden" }}
                      src={`assets/elements/images/leftChevron.png`}
                      alt="single_photo"
                      width={30}
                    />
                  </div>
                )}

                <div>
                  <div
                    style={{
                      height: "70vh",
                      width: "68vw",
                      margin: "auto",
                      paddingRight: "1rem",
                      paddingLeft: "1rem"
                    }}
                    className="img-modal-container"
                  >
                    <img
                      className="modal__single-image"
                      src={imagesList[imageIndex]}
                      alt="single_photo"
                    />
                  </div>
                </div>

                {imageIndex !== imagesList.length - 1 ? (
                  <div onClick={nextImage}>
                    <img
                      src={"assets/elements/images/rightChevron.png"}
                      alt="single_photo"
                      style={{ cursor: "pointer" }}
                      width={30}
                    />
                  </div>
                ) : (
                  <div onClick={nextImage}>
                    <img
                      src={"assets/elements/images/rightChevron.png"}
                      alt="single_photo"
                      style={{ cursor: "pointer", visibility: "hidden" }}
                      width={30}
                    />
                  </div>
                )}
              </div>
            </Modal.Body>
            <div
              className="modal__gallery__bottom"
              style={{
                width: "65vw",
                margin: "auto",
              }}
            >
              {imagesList.map((image, i) => (
                <div key={i} className="modal__gallery_preview" onClick={() => setImageIndex(i)}>
                  <div className={`${i === imageIndex ? "" : " not__selected__img"}`}/>
                  <img className={`modal__gallery__preview_img`} src={image} alt="" />
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ImageCarosel;
