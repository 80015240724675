import React, { useState } from "react";

import shark from "../../../assets/images/Shark-image-small-2.png";
import turtle from "../../../assets/images/Turtle-image-small copy.png";
import dolphin from "../../../assets/images/DolphinCompressed.png";
import ImageCarosel from "../../modals/ImageCarosel";
import DetailModal from "../../modals/observations/detailObs/DetailModal";
import EditObservation from "../../modals/observations/editObs/EditObservation";

const GetId = ({ data }) => {
  const staticImages = [shark, turtle, dolphin];
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [isEditObservation, setIsEditObservation] = useState(false);
  const [showImageCarosel, setShowImageCarosel] = useState(false);

  const closeDetailModal = () => {
    setShowDetailModal(false);
  };
  const closeIsEditModal = () => {
    setIsEditObservation(false);
  };
  const openIsEditModal = () => {
    setIsEditObservation(true);
  };
  const handleShowImageCarosel = () => {
    setShowImageCarosel(true);
  };
  const hideImageCarosel = () => {
    setShowImageCarosel(false);
  };

  return (
    <div>
      <div
        className="obsId"
        onClick={() => {
          setShowDetailModal(true);
        }}
      >
        {data.id}
      </div>

      {showDetailModal && (
        <DetailModal
          editData={data}
          closeDetailModal={closeDetailModal}
          openIsEditModal={openIsEditModal}
          handleShowImageCarosel={handleShowImageCarosel}
        />
      )}

      {isEditObservation && (
        <EditObservation
          editData={data}
          closeIsEditModal={closeIsEditModal}
          // index={rowIndex}
        />
      )}

      {showImageCarosel && (
        <ImageCarosel
          staticImageList={staticImages}
          images={data.images}
          hideImageCarosel={hideImageCarosel}
          setShowDetailModal={setShowDetailModal}
        />
      )}
    </div>
  );
};

export default GetId;
