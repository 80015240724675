import React, { useState, useEffect } from "react";
import moment from "moment";
import GridTable from "@nadavshaar/react-grid-table";
import "./ListViewTable.css";
import { useSelector } from "react-redux";

import CustomHeader from "./custom/TableHeader";
import TableLoader from "./custom/TableLoader";
import NoObservation from "./custom/NoObservation";

import CustomSuccess from "../Alerts/CustomSuccess";
import CustomError from "../Alerts/CustomError";

import Loading2 from "../LoadingError/Loading2";
import carret from "../../assets/images/pointbas.png";
import {
  useFetchMiniCardObservaionList,
  useFetchObservaionList,
  useFetchUnvalidatedObservaionList,
} from "../../queries/useMapQueriesHook";

import {
  useFetchSingleObs,
  useNestIdAvailabilityCheck,
  useSegmentAvailabilityCheck,
} from "../../queries/useFetchOptions";

import { useTranslation } from "react-i18next";
import GetColumnsUnvalidate from "./getColumnUnvalidate";
import InvalidInformation from "./custom/InvalidInformation";
import { useCreateNewFemaleEncounter } from "../../lib/createObs/useCreateObservationData";

const UnValidatedTable = ({
  dates,
  handleCallback,
  obsData,
  obsListLoading,
}) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const singleObservationData = useSelector((state) => state.singleObsData);
  const { singleObsId } = singleObservationData;

  const {
    queryRefetchObsList,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
  } = useFetchUnvalidatedObservaionList({
    id: localProjectId,
  });
  const { queryRefetchValidObsList } = useFetchObservaionList({
    id: localProjectId,
  });
  const { miniCardQueryRefetchObsList } = useFetchMiniCardObservaionList({
    id: localProjectId,
  });
  const { refetchSIngleObs } = useFetchSingleObs({
    singleObsId,
  });

  const successMessage = useSelector((state) => state.setSuccessMessage);
  const {
    createObsSuccessNotif,
    updateObsSuccessNotif,
    deleteObsSuccessNotif,
    validateObsSuccessNotif,
    deleteObsImageNotif,
  } = successMessage;
  const errorMessage = useSelector((state) => state.setErrorMessage);
  const {
    createObsErrorNotif,
    updateObsErrorNotif,
    deleteObsErrorNotif,
    validateObsErrorNotif,
  } = errorMessage;

  // table sort icons

  var head = document.getElementsByClassName("rgt-cell-header-inner");

  for (var i = 2; i < head.length - 1; i++) {
    head[i].style.padding = "0px";
  }

  const createObs = useSelector((state) => state.createObservation);
  const { obsCreateLoading } = createObs;
  const [rowData, setRowsData] = useState(obsData);

  const validObs = useSelector(
    (state) => state.validateUnvalidatedObservations
  );
  const { validateLoading } = validObs;

  // delete list of observations
  const deleteList = useSelector((state) => state.deleteNumerousObservation);
  const { deleteListLoading } = deleteList;

  // delete single observations
  const deleteSingle = useSelector((state) => state.deleteObservation);
  const { deleteLoading } = deleteSingle;
  const updatedLocalProject = useSelector(
    (state) => state.updateProjectLocally
  );
  const { updtedObservationData } = updatedLocalProject;
  // Update state
  const editeObservation = useSelector((state) => state.editeObservation);
  const { updateLoading } = editeObservation;

  // Fetch unvalidated obserations with react query

  useEffect(() => {
    if (singleObsId && updateObsSuccessNotif) {
      refetchSIngleObs();
    }
    if (singleObsId && createObsSuccessNotif) {
      refetchSIngleObs();
    }
  }, [
    singleObsId,
    refetchSIngleObs,
    updateObsSuccessNotif,
    createObsSuccessNotif,
  ]);

  useEffect(() => {
    setRowsData(obsData);
  }, [obsData, dates]);

  // get projects ids functions

  const [selectedRows, setSelectedRows] = useState();

  useEffect(() => {
    handleCallback(selectedRows);
  }, [selectedRows, handleCallback]);

  //  Show notifications create project notif success

  // error notif

  // Update obs list locally
  /*************************************************************************** */

  const localDeleteId = useSelector((state) => state.deleteProjectLocally);
  const { deleteId } = localDeleteId;
  useEffect(() => {
    if (deleteId && deleteId.length !== 0) {
      if (deleteObsSuccessNotif && deleteObsSuccessNotif === true) {
        const results = obsData?.filter((obs) => !deleteId.includes(obs.id));
        //  setAllValideObs(results)

        setRowsData(results);
      }
    }
  }, [deleteId, obsData, deleteObsSuccessNotif]);

  // Get updated obs and update  array

  useEffect(() => {
    if (updtedObservationData !== null) {
      // Iterate through the array using map
      if (updateObsSuccessNotif && updateObsSuccessNotif === true) {
        let uptObj = updtedObservationData;
        let updatedArray = obsData.map((obj) => {
          if (obj.id === uptObj.id) {
            return uptObj; // Replace with the separate object
          } else {
            return obj; // Keep the object as it is
          }
        });
        setRowsData(updatedArray);
      }
    }
  }, [updtedObservationData, obsData, updateObsSuccessNotif]);

  // Validate observationLocally

  const localValidateteId = useSelector((state) => state.valideProjectLocally);
  const { valideId } = localValidateteId;
  useEffect(() => {
    if (valideId && valideId.length !== 0) {
      if (validateObsSuccessNotif && validateObsSuccessNotif === true) {
        const results = obsData?.filter((obs) => !valideId.includes(obs.id));
        //  setAllValideObs(results)

        setRowsData(results);
      }
    }
  }, [valideId, obsData, validateObsSuccessNotif]);

  useEffect(() => {
    if (validateObsSuccessNotif && validateObsSuccessNotif === true) {
      queryRefetchObsList();
      queryRefetchValidObsList();
      miniCardQueryRefetchObsList();
    }
  }, [validateObsSuccessNotif]);

  // dispatch(changeMarker({ id: "", index: "" }));
  /****************************************************************************/

  // Translation

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  // Listen for language change and update the state
  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(i18n.language);
    };
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);
  const { isSegAvailable } = useSegmentAvailabilityCheck(localProjectId);
  const { isNestIdAvailable } = useNestIdAvailabilityCheck(localProjectId);

  const [endRow, setEndRow] = useState(null);
  const [totalRows, setTotalRows] = useState(null);

  const handleFetchMore = () => {
    if (totalRows - endRow < 51) {
      fetchNextPage();
    }
  };

  const { femaleCreated } = useCreateNewFemaleEncounter();
  return (
    <>
      <div className="list__tables">
        {createObsSuccessNotif &&
        createObsSuccessNotif === true &&
        !femaleCreated ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.obsCreated")}`}
          />
        ) : (
          ""
        )}

        {updateObsSuccessNotif && updateObsSuccessNotif === true ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.obsUpdated")}`}
          />
        ) : (
          ""
        )}
        {deleteObsSuccessNotif && deleteObsSuccessNotif === true ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.obsDeleted")}`}
          />
        ) : (
          ""
        )}
        {validateObsSuccessNotif && validateObsSuccessNotif === true ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.obsValidated")}`}
          />
        ) : (
          ""
        )}
        {deleteObsImageNotif && deleteObsImageNotif === true ? (
          <CustomSuccess
            message={`${t("listview.notificationMessages.imgDeleted")}`}
          />
        ) : (
          ""
        )}

        {/* Error notiff */}
        {createObsErrorNotif && createObsErrorNotif === true ? (
          <CustomError
            message={`${t("listview.notificationMessages.errorOccured")}`}
          />
        ) : (
          ""
        )}
        {updateObsErrorNotif && updateObsErrorNotif === true ? (
          <CustomError
            message={`${t("listview.notificationMessages.errorOccured")}`}
          />
        ) : (
          ""
        )}
        {deleteObsErrorNotif && deleteObsErrorNotif === true ? (
          <CustomError
            message={`${t("listview.notificationMessages.errorOccured")}`}
          />
        ) : (
          ""
        )}
        {validateObsErrorNotif && validateObsErrorNotif === true ? (
          <CustomError
            message={`${t("listview.notificationMessages.errorOccured")}`}
          />
        ) : (
          ""
        )}

        {obsListLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {isRefetching && (
          <div>
            <Loading2 />
          </div>
        )}
        {isFetchingNextPage && (
          <div>
            <Loading2 />
          </div>
        )}
        {obsCreateLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              overflow: "auto",
              transition: "all 0.5s ease-in-out",
              zIndex: "99999",
            }}
          >
            <Loading2 />
          </div>
        )}
        {updateLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {deleteLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {validateLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {deleteListLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        {obsListLoading && (
          <div>
            <Loading2 />
          </div>
        )}
        <GridTable
          enableColumnsReorder={false}
          columns={GetColumnsUnvalidate({
            obsData,
            setRowsData,
            isNestIdAvailable,
            isSegAvailable,
          })}
          key={`${language}-${isSegAvailable}-${localProjectId}-${isNestIdAvailable}-${obsData}`}
          // selectAllMode={"all"}
          selectedRowsIds={selectedRows}
          onSelectedRowsChange={setSelectedRows}
          icons={{
            sortAscending: (
              <img className="sortB rBottom" src={carret} alt="sortB" />
            ),
            sortDescending: <img className="sortB" src={carret} alt="sortB" />,
          }}
          components={{
            Header: CustomHeader,
            Loader: TableLoader,
            NoResults: NoObservation,
            Information: (props) => (
              <InvalidInformation
                {...props}
                dates={dates}
                setEndRow={setEndRow}
                setTotalRows={setTotalRows}
              />
            ),
          }}
          texts={{
            search: `${t("gridTable.search")}`,
            totalRows: `${t("gridTable.totalRows")}`,
            rows: `${t("gridTable.rows")}`,
            selected: `${t("gridTable.selected")}`,
            rowsPerPage: `${t("gridTable.rowsPerPage")}`,
            page: "Page:",
            of: `${t("gridTable.of")}`,
            prev: `${t("gridTable.prev")}`,
            next: `${t("gridTable.next")}`,
          }}
          showSearch={false}
          rows={rowData}
          onPageChange={handleFetchMore}
        />
      </div>
    </>
  );
};

export default UnValidatedTable;
