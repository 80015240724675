import React from "react";
import { useEditObservationStore } from "../../../../../../lib/editObservation/useEditObservationData";

const EditNewNest = ({ editData }) => {
  const {
    newNestId,
    setNewNestID,
    setNewNestCollector,
    setNewNestHighTideDist,
    setNewNestStatus,
    setNewNestEstHatchDate,
    setNewNestEstExhumDate,
    setChangeTracker,
  } = useEditObservationStore();
  return (
    <>
      <div
        className="row px-3 modal__obs__details__row mt-1"
        style={{ borderTop: ".5px solid #CCCCCC" }}
      >
        <div className="col-5 modal__detail__title">
          Nest ID <sup>*</sup>
        </div>
        <div className="col-7 modal__title__value">
          <input
            type="text"
            id="nest id"
            name="newNestID"
            className="crud_select crud-input"
            style={{ width: "101%" }}
            value={editData.nest_id}
            onChange={(e) => {
              setNewNestID(e.target.value);
              setChangeTracker(true);
            }}
          />
        </div>
      </div>

      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">Nest Collector</div>
        <div className="col-7 modal__title__value">
          <input
            type="text"
            id="nest collector"
            name="newNestCollector"
            className="crud_select crud-input"
            style={{ width: "101%" }}
            value={editData.collector}
            onChange={(e) => {
              setNewNestCollector(e.target.value);
              setChangeTracker(true);
            }}
          />
        </div>
      </div>

      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          Nest High Tide Distance
        </div>
        <div className="col-7 modal__title__value">
          <div className="input-container">
            <input
              inputMode="numeric"
              type="text"
              id="crawl-width"
              name="newNestHighTideDist"
              className="crud_select crud-input"
              style={{ width: "101%" }}
              // value={editData.nestHighTideDist} Needs to be added to API
              onChange={(e) => {
                setNewNestHighTideDist(e.target.value);
                setChangeTracker(true);
              }}
            />
            <span className="unit">(m)</span>
          </div>
        </div>
      </div>

      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">Nest Status</div>
        <div className="col-7 modal__title__value ">
          <select
            type="text"
            id="nest status"
            name="newNestStatus"
            placeholder="Select Nest Status"
            className="crud_select crud-input"
            style={{ width: "101%" }}
            // value={editData.nestStatus} Needs to be added to API
            onChange={(e) => {
              setNewNestStatus(e.target.value);
              setChangeTracker(true);
            }}
          >
            <option disabled selected value="">
              Select Nest Status
            </option>
            <option value="Nest Undisturbed">Nest Undisturbed</option>
            <option value="Nest Partially Lost">Nest Partially Lost</option>
            <option value="Nest Totally Lost">Nest Totally Lost</option>
          </select>
        </div>
      </div>

      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          Nest Estimated Hatch Date
        </div>
        <div className="col-7 modal__title__value">
          <input
            type="date"
            id="nest est hatch date"
            name="newNestEstHatchDate"
            className="crud_select crud-input"
            style={{ width: "101%" }}
            // value={editData.nestEstHatchDate} Needs to be added to API
            onChange={(e) => {
              setNewNestEstHatchDate(e.target.value);
              setChangeTracker(true);
            }}
          />
        </div>
      </div>

      <div className="row px-3 modal__obs__details__row mt-1">
        <div className="col-5 modal__detail__title">
          Nest Estimated Exhumation Date
        </div>
        <div className="col-7 modal__title__value">
          <input
            type="date"
            id="nest est exhum date"
            name="newNestEstExhumDate"
            className="crud_select crud-input"
            style={{ width: "101%" }}
            // value={editData.nestEstHatchDate} Needs to be added to API
            onChange={(e) => {
              setNewNestEstExhumDate(e.target.value);
              setChangeTracker(true);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EditNewNest;
